import { API_ROUTES } from '../../constants/apiRoutes';
import api from '../../utils/axios';

export const getInterviewsListApi = async ({
  pageNumber,
  searchValue,
  sortBy,
  orderBy,
  interviewRoundStatus,
  technology,
  size,
  result,
  filterByHR,
  viewMode
}) => {
  try {
    const response = await api.get(
      `${
        API_ROUTES.INTERVIEW_LIST
      }?page=${pageNumber}&searchName=${searchValue}&technologies=${technology}&sortBy=${sortBy}&orderBy=${orderBy}&interviewRoundStatus=${
        interviewRoundStatus || ''
      }&size=${size}&interviewResult=${result || ''}&viewMode=${viewMode}&filterByHR=${filterByHR}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const addInterviewsApi = async (interviewDetails) => {
  try {
    const response = await api.post(API_ROUTES.INTERVIEW_ROUND_ADD, interviewDetails);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const interviewScheduleListApi = async ({
  pageNumber,
  searchValue,
  sortBy,
  orderBy,
  size
}) => {
  try {
    const response = await api.get(
      `${API_ROUTES.INTERVIEW_SCHEDULED}?page=${pageNumber}&searchName=${searchValue}&sortBy=${sortBy}&orderBy=${orderBy}&size=${size}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const interviewInvitationListApi = async ({
  pageNumber,
  searchValue,
  sortBy,
  orderBy,
  size,
  invitationStatus
}) => {
  try {
    const response = await api.get(
      `${
        API_ROUTES.INTERVIEW_INVITATIONS
      }?page=${pageNumber}&searchName=${searchValue}&sortBy=${sortBy}&orderBy=${orderBy}&size=${size}&invitationStatus=${
        invitationStatus || ''
      }`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const interviewPendingFeedbackListApi = async ({
  pageNumber,
  searchValue,
  sortBy,
  orderBy,
  size
}) => {
  try {
    const response = await api.get(
      `${API_ROUTES.INTERVIEW_PENDING_FEEDBACK}?page=${pageNumber}&searchName=${searchValue}&sortBy=${sortBy}&orderBy=${orderBy}&size=${size}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const interviewInitationStatusApi = async ({ id, rejectionReason, invitationStatus }) => {
  try {
    const response = await api.put(`${API_ROUTES.INTERVIEW_INVITATION_STATUS}/${id}`, {
      invitationStatus,
      rejectionReason
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const interviewStatusApi = async ({ id, interviewStatus }) => {
  try {
    const response = await api.put(`${API_ROUTES.INTERVIEW_STATUS}/${id}`, {
      interviewStatus
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getInterviewDetailsApi = async (interviewId) => {
  try {
    const response = await api.get(`${API_ROUTES.HR_INTERVIEW_DETAILS}/${interviewId}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const editInterviewApi = async (interviewDetails) => {
  try {
    const response = await api.put(
      `${API_ROUTES.INTERVIEW_ROUND_ADD}/${interviewDetails.interviewId}`,
      interviewDetails.postData
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const editInterviewsApi = async (interviewDetails) => {
  try {
    const response = await api.put(
      `${API_ROUTES.HR_INTERVIEW_EDIT}/${interviewDetails.interviewId}`,
      interviewDetails.postDataEdit
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const interviewHistoryListApi = async ({
  pageNumber,
  searchValue,
  sortBy,
  orderBy,
  size
}) => {
  try {
    const response = await api.get(
      `${API_ROUTES.INTERVIEW_HISTORY}?page=${pageNumber}&searchName=${searchValue}&sortBy=${sortBy}&orderBy=${orderBy}&size=${size}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const addFeedbackApi = async (feedbackdata) => {
  delete feedbackdata.interviewType;
  try {
    const response = await api.put(API_ROUTES.INTERVIEW_RATING, feedbackdata);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateFeedbackApi = async (feedbackdata) => {
  delete feedbackdata.interviewType;
  try {
    const response = await api.put(API_ROUTES.COMPLETE_INTERVIEW_FEEDBACK, feedbackdata);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getTechparemsApi = async (payload) => {
  try {
    const response = await api.get(
      `${API_ROUTES.INTERVIEW_TECHPARAMS}?technology=${payload.technology}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getInterviewRoundScheduleDetailsApi = async (payload) => {
  try {
    const response = await api.get(`${API_ROUTES.INTERVIEW_ROUND_SCHEDULE_DETAILS}/${payload}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const addInterviewRoundApi = async ({ interviewID, formdataJson }) => {
  try {
    const response = await api.post(
      `${API_ROUTES.INTERVIEW_ROUND_ADD_NEW}/${interviewID}`,
      formdataJson,
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const editInterviewRoundApi = async ({ interviewRoundID, formdataJson }) => {
  try {
    const response = await api.put(
      `${API_ROUTES.INTERVIEW_ROUND_ADD_NEW}/${interviewRoundID}`,
      formdataJson,
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getHrFeedbackHistoryLogAPi = async ({
  pageNumber,
  sortBy,
  orderBy,
  size,
  interviewId
}) => {
  try {
    const response = await api.get(
      `${API_ROUTES.HR_FEEDBACK_HISTORY_LOG}/${interviewId}?page=${pageNumber}&sortBy=${sortBy}&orderBy=${orderBy}&size=${size}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const cancelInterviewsAPI = async (cancelInterviewObj) => {
  const { interviewId, cancelReason } = cancelInterviewObj;
  try {
    const response = await api.put(`${API_ROUTES.INTERVIEW_CANCEL}/${interviewId}`, {
      cancellationReason: cancelReason
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const cancelInterviewRoundsAPI = async (cancelInterviewRoundObj) => {
  const { interviewId, cancelReason } = cancelInterviewRoundObj;
  try {
    const response = await api.put(`${API_ROUTES.INTERVIEW_ROUND_CANCEL}/${interviewId}`, {
      cancellationReason: cancelReason
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const rejectCandidateInterviewRoundsAPI = async (InterviewRoundObj) => {
  const { interviewRoundId, result } = InterviewRoundObj;
  try {
    const response = await api.put(
      `${API_ROUTES.REJECT_CANDIDATE_INTERVIEW_ROUND}/${interviewRoundId}`,
      { result }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
