import {
  INTERVIEWERS_LIST_REQUEST,
  INTERVIEWERS_ADD_REQUEST,
  INTERVIEWERS_EDIT_REQUEST,
  INTERVIEWERS_DELETE_REQUEST,
  INTERVIEWER_SCHEDULE_REQUEST,
  INTERVIEWER_INVITATION_REQUEST,
  INTERVIEWER_FEEDBACK_REQUEST,
  USER_STATUS,
  INTERVIEWER_AVAILABLEILITY_REQUEST,
  INTERVIEWER_AVAILABLEILITY_ADD_REQUEST,
  UPDATE_MOBILE_NUMBER
} from '../../constants/redux';

export const getInterviewersList = (payload) => {
  return {
    type: INTERVIEWERS_LIST_REQUEST,
    payload
  };
};
export const getInterviewersAvailbility = (id) => {
  return {
    type: INTERVIEWER_AVAILABLEILITY_REQUEST,
    payload: id
  };
};

export const addInterviewersAvailbility = (availbility) => {
  return {
    type: INTERVIEWER_AVAILABLEILITY_ADD_REQUEST,
    payload: availbility
  };
};

export const addInterviewer = (interviewerDetails) => {
  return {
    type: INTERVIEWERS_ADD_REQUEST,
    payload: interviewerDetails
  };
};

export const deleteInterviewer = (interviewerId) => {
  return {
    type: INTERVIEWERS_DELETE_REQUEST,
    payload: interviewerId
  };
};

export const editInterviewer = (Details) => {
  return {
    type: INTERVIEWERS_EDIT_REQUEST,
    payload: Details
  };
};

export const getInterviewersSchedule = (date, size) => {
  return {
    type: INTERVIEWER_SCHEDULE_REQUEST,
    payload: { date, size }
  };
};
export const getInterviewersInvitation = (size) => {
  return {
    type: INTERVIEWER_INVITATION_REQUEST,
    payload: size
  };
};
export const getInterviewersPendingFeedback = () => {
  return {
    type: INTERVIEWER_FEEDBACK_REQUEST
  };
};
export const userStatus = (payload) => {
  return {
    type: USER_STATUS,
    payload
  };
};

export const updateMobile = (Details) => {
  return {
    type: UPDATE_MOBILE_NUMBER,
    payload: Details
  };
};
