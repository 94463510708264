import React from 'react';

// Prime-React-Components
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import InterviewerAvailability from '../../pages/interviewers/InterviewerAvailability';

const EditInterviewerAvailability = (props) => {
  const { onHide, ID, name, show } = props;

  return (
    <Dialog
      header={`${name ? `${name}'s` : ''} Availability Details`}
      style={{ width: '800px' }}
      className={classNames('card py-0 availability-detail-popup')}
      visible={show}
      onHide={onHide}>
      <InterviewerAvailability interviewerId={ID} />
    </Dialog>
  );
};

export default EditInterviewerAvailability;
