import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Components
import Label from '../../components/Label';
import CustomButton from '../../components/CustomButton';

// Prime-React Components
import { classNames } from 'primereact/utils';
import { Sidebar } from 'primereact/sidebar';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';

// Redux-Actions
import {
  addCommonParams,
  editCommonParames,
  getCommonParamsById,
  getFilterCommonParamsList
} from '../../redux/actions/commonParameter';

// Redux-Slices
import { apiStatusClear } from '../../redux/slices/apiStatus';
import { updateData } from '../../redux/slices/commonParamters';

// Constants
import { BUTTON_TYPES, INITIAL_PAGE_NUMBER, LABEL_TYPES, ROUTES } from '../../constants/common';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';

const AddEditCommonParams = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { show, onHide, ID } = props;
  const { parameterDetails, filterParameters } = useSelector((state) => state.parameter);

  const { isSucceed, isLoading } = useSelector((state) => state.apiStatus);
  const [isUpdatedSuccess, setIsUpdatedSuccess] = useState(false);
  const [feedbackParamsOptions, setFeedbackParamsOptions] = useState([]);
  const [parameterData, setParameterData] = useState({
    parameterName: '',
    isGroupParameter: false,
    subParameterIds: []
  });

  const queryParamsOfFeedbackParams = {
    isSingleParameter: true,
    searchValue: '',
    pageNumber: INITIAL_PAGE_NUMBER,
    size: 10000,
    sortBy: 'DateModified',
    orderBy: 'desc'
  };

  const feedbackParamsOptionsGenerate = (options) => {
    if (!options?.length) return [];

    return options
      ?.filter((param) => param.ID !== ID)
      .map((opt) => {
        return {
          name: `${opt.ParameterName}`,
          code: `${opt.ID}`,
          key: `${opt.ID}`
        };
      });
  };

  useEffect(() => {
    const modifiedOptions = feedbackParamsOptionsGenerate(filterParameters || []);
    setFeedbackParamsOptions(modifiedOptions);
  }, [filterParameters]);

  useEffect(() => {
    if (show) {
      dispatch(getFilterCommonParamsList(queryParamsOfFeedbackParams));
    }
  }, [show]);

  useEffect(() => {
    ID && dispatch(getCommonParamsById(ID));
  }, [dispatch, ID]);

  useEffect(() => {
    if (ID && parameterDetails && Object.keys(parameterDetails).length !== 0) {
      setParameterData({
        parameterName: parameterDetails?.ParameterName,
        isGroupParameter: !parameterDetails?.IsSingleParameter,
        subParameterIds: parameterDetails?.GroupParameters?.length
          ? parameterDetails?.GroupParameters.map((param) => param.FeedBackParameterID)
          : []
      });
    }
  }, [parameterDetails]);

  useEffect(() => {
    if (show && isUpdatedSuccess && isSucceed) {
      onHide(false);
      formik.resetForm();
      document.body.classList.remove('sidebar-open');
      dispatch(updateData());
      dispatch(apiStatusClear());
      setParameterData({
        parameterName: '',
        isGroupParameter: false,
        subParameterIds: []
      });
      setIsUpdatedSuccess(false);
      navigate(ROUTES.ADMIN.COMMON_PARAMETERS);
    }
  }, [isSucceed]);

  useEffect(() => () => setIsUpdatedSuccess(false), []);

  const formik = useFormik({
    initialValues: parameterData,
    validationSchema: Yup.object({
      parameterName: Yup.string()
        .required('Parameter is required.')
        .max(100, 'Must be 100 characters or less'),
      subParameterIds: Yup.array().when('isGroupParameter', {
        is: true,
        then: Yup.array()
          .of(Yup.string('Should be of type string.'))
          .min(1, 'Please add at least one Parameter.')
          .required('Sub Parameters are required.'),
        otherwise: Yup.array().notRequired()
      })
    }),

    onSubmit: (values) => {
      if (ID) {
        dispatch(
          editCommonParames({
            formdata: {
              parameterName: values.parameterName,
              isSingleParameter: !values.isGroupParameter,
              subParameterIds: values.subParameterIds
            },
            ID: ID
          })
        );
      } else {
        dispatch(
          addCommonParams({
            parameterName: values.parameterName,
            isSingleParameter: !values.isGroupParameter,
            subParameterIds: values.subParameterIds
          })
        );
      }
      setIsUpdatedSuccess(true);
    },
    enableReinitialize: true
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  return (
    <Sidebar
      ID={ID}
      visible={show}
      onHide={() => {
        onHide(true);
        document.body.classList.remove('sidebar-open');
      }}
      position="right"
      className="sidebar-drawer">
      <div className="form-box-wrapper">
        <div className="title-wrapper">
          <p className="card-title">{ID ? 'Edit' : 'Add'} Feedback Parameter</p>
          <button
            className="p-sidebar-close"
            onClick={() => {
              onHide(false);
              document.body.classList.remove('sidebar-open');
              setParameterData({
                parameterName: '',
                isGroupParameter: false,
                subParameterIds: []
              });
              formik.resetForm();
            }}>
            <span className="p-sidebar-close-icon pi pi-times" />
          </button>
        </div>
        <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
          <div className="form-row-wrapper">
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label for="parameterName" text={LABEL_TYPES.PARAMETER} isMandatory />
                  <InputText
                    id="parameterName"
                    name="parameterName"
                    value={formik.values.parameterName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Add Parameter *"
                    className={classNames({
                      'p-invalid': isFormFieldValid('parameterName')
                    })}
                  />
                </div>
                {getFormErrorMessage('parameterName')}
              </div>
            </div>
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group horizontal-switch">
                  <Label for="isGroupParameter" text={LABEL_TYPES.IS_GROUPED} />
                  <InputSwitch
                    checked={formik.values.isGroupParameter}
                    onChange={(e) => {
                      formik.setFieldValue('isGroupParameter', e.value);
                      if (!e.value) {
                        formik.setFieldValue('subParameterIds', []);
                      }
                    }}
                  />
                </div>
                {getFormErrorMessage('isGroupParameter')}
              </div>
            </div>
            {formik.values.isGroupParameter && (
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group autocomplete">
                    <Label htmlFor="subParameterIds" text={LABEL_TYPES.SUB_PARAMETERS} />
                    <MultiSelect
                      name="subParameterIds"
                      id="subParameterIds"
                      optionLabel="name"
                      optionValue="code"
                      value={formik.values.subParameterIds}
                      onChange={formik.handleChange}
                      options={feedbackParamsOptions}
                      placeholder="Select Feedback Params"
                      filter
                      display="chip"
                      className={classNames({
                        'p-invalid': isFormFieldValid('subParameterIds')
                      })}
                    />
                  </div>
                  {getFormErrorMessage('subParameterIds')}
                </div>
              </div>
            )}
          </div>
          <div className="form-btn-wrapper">
            <CustomButton type="submit" variant="contained" className="" disabled={isLoading}>
              {BUTTON_TYPES.SAVE}
            </CustomButton>
            <CustomButton
              variant="contained"
              onClick={() => {
                onHide(false);
                document.body.classList.remove('sidebar-open');
                setParameterData({
                  parameterName: '',
                  isGroupParameter: false,
                  subParameterIds: []
                });
                formik.resetForm();
              }}
              color="error"
              className="gray-btn">
              {BUTTON_TYPES.CANCEL}
            </CustomButton>
          </div>
        </form>
      </div>
    </Sidebar>
  );
};

export default AddEditCommonParams;
