import api from '../../utils/axios';

//Constants
import { API_ROUTES } from '../../constants/apiRoutes';

export const hrlistApi = async ({ pageNumber, searchValue, sortBy, orderBy, size }) => {
  try {
    const response = await api.get(
      `${API_ROUTES.HRS_LIST}?page=${pageNumber}&searchName=${searchValue}&sortBy=${sortBy}&orderBy=${orderBy}&size=${size}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const filteredHrListApi = async () => {
  try {
    const response = await api.get(API_ROUTES.FILTERED_HRS_LIST);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const addHRApi = async (hrDetails) => {
  try {
    const response = await api.post(API_ROUTES.HR_ADD, hrDetails);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteHRApi = async (hrId) => {
  try {
    const response = await api.delete(`${API_ROUTES.USER_DETAILS}/${hrId}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const editHRApi = async (details) => {
  try {
    const response = await api.put(`${API_ROUTES.USER_DETAILS}/${details.HrId}`, details.formData);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAvailableScheduleApi = async () => {
  try {
    const response = await api.get(API_ROUTES.HR_AVAILABLE_SCHEDULE);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getScheduleInterviewApi = async ({ monthName, interviewRoundStatusForDashboard }) => {
  try {
    const response = await api.get(
      `${API_ROUTES.HR_DASHBOARD}?monthName=${monthName}&interviewRoundStatusForDashboard=${interviewRoundStatusForDashboard}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getJobDesignationApi = async () => {
  try {
    const response = await api.get(API_ROUTES.HR_JOB_DESIGNATION);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getInterviewTechnologyApi = async () => {
  try {
    const response = await api.get(API_ROUTES.HR_TECHNOLOGY);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getJobDesignationBySearchApi = async (searchText) => {
  try {
    const response = await api.get(`${API_ROUTES.HR_JOB_DESIGNATION}?searchName=${searchText}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getInterviewTechnologyBySearchApi = async (searchText) => {
  try {
    const response = await api.get(`${API_ROUTES.HR_TECHNOLOGY}?searchName=${searchText}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getInterviewerAvailiblityByFilter = async ({
  searchName,
  technology,
  pageNumber,
  sortBy,
  orderBy,
  startDateFilter,
  size,
  practicalOptionFilter
}) => {
  try {
    let url = `${
      API_ROUTES.HR_INTERVIEWER_AVAILABLITY
    }?searchName=${searchName}&technology=${technology}&startDateFilter=${
      startDateFilter || ''
    }&page=${pageNumber}&sortBy=${sortBy}&orderBy=${orderBy}&size=${size}`;

    if (
      practicalOptionFilter &&
      Array.isArray(practicalOptionFilter) &&
      practicalOptionFilter.length > 0
    ) {
      practicalOptionFilter.forEach((item) => {
        url += `&${encodeURIComponent(item)}=true`;
      });
    }
    const response = await api.get(url);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getScheduleInterviewCountByHRApi = async ({
  interviewStartTime,
  interviewEndTime
}) => {
  try {
    const response = await api.get(
      `${API_ROUTES.HR_SCHEDULE_INTERVIEW_COUNT}?roundStartDate=${interviewStartTime}&roundEndDate=${interviewEndTime}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getInterviewersBySearchNameApi = async (filterObj) => {
  try {
    const params = {};
    const { search, technologyId } = filterObj;
    // Assign the search name to URL params if exist.
    if (search) {
      Object.assign(params, {
        searchName: search
      });
    }
    // Assign the technology filter to URL params if exist.
    if (technologyId) {
      Object.assign(params, {
        technology: technologyId
      });
    }
    // Call the API which params.
    const response = await api.get(API_ROUTES.HR_INTERVIEWER_AVAILABLITY, {
      params
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
