import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_PAGE_NUMBER, INITIAL_SIZE, INTERVIEW_MODE_LIST } from '../../constants/common';

const initialState = {
  data: [],
  pagination: {},
  InterviewDetails: {},
  tableStates: {
    searchValue: '',
    pageNumber: INITIAL_PAGE_NUMBER,
    size: INITIAL_SIZE,
    sortBy: 'DateModified',
    orderBy: 'desc',
    technology: '',
    interviewRoundStatus: null,
    result: null,
    filterByHR: '',
    viewMode: INTERVIEW_MODE_LIST.LIST
  }
};

const interviewsSlice = createSlice({
  name: 'interviews',
  initialState,
  reducers: {
    getInterviews(state, action) {
      const { data, pagination, tableStates } = action.payload;
      return { ...state, data, pagination, tableStates, isUpdated: false, isAdded: false };
    },
    getInterviewsStatus(state, action) {
      const { data, pagination } = action.payload;
      return { ...state, data, pagination };
    },
    getInterviewDetails(state, action) {
      const InterviewDetails = { ...action.payload };
      return { ...state, InterviewDetails: InterviewDetails };
    },
    getTecharams(state, action) {
      const TechparamsDetails = action.payload;
      const { requiredParams, commonParams } = TechparamsDetails;
      return {
        ...state,
        RequiredParams: requiredParams,
        CommonParams: commonParams
      };
    },
    redirectionOnStatusChange(state, action) {
      return {
        ...state,
        ...action.payload
      };
    },
    interviewTableStatus(state, action) {
      return { ...state, interviewRoundStatus: action.payload };
    },
    interviewTableType(state, action) {
      return { ...state, interviewRoundType: action.payload };
    },
    getHistoryLogs(state, action) {
      const { data, pagination } = action.payload;
      return { ...state, data, pagination };
    },
    updateData(state) {
      return { ...state, isUpdated: true, isAdded: true };
    },
    scheduleDetails(state, action) {
      const scheduleDetails = action.payload;
      return { ...state, scheduleDetails };
    },
    cancelInterview(state, action) {
      const interviewId = action.payload;
      const clonedInterviewData = [...state.data];
      const interviewList = clonedInterviewData.filter((interview) => interview.ID !== interviewId);
      return { ...state, data: interviewList, isCancelled: true };
    },
    cancelInterviewRound(state) {
      return { ...state, isCancelled: !state?.isCancelled };
    }
  }
});

export const {
  getInterviews,
  getInterviewDetails,
  redirectionOnStatusChange,
  getTecharams,
  interviewTableStatus,
  interviewTableType,
  getInterviewsStatus,
  getHistoryLogs,
  updateData,
  cancelInterview,
  scheduleDetails,
  cancelInterviewRound
} = interviewsSlice.actions;
export default interviewsSlice.reducer;
