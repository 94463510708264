import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';

// Constants
import { INTERVIEW_STATUS_LIST, INTERVIEW_TYPE, STATUS_INTERVIEW } from '../../constants/common';
import { INTERVIEW_ROUND_SCHEDULE_DETAILS } from '../../constants/tableColumns';
import { formatTime, getFormattedDateView } from '../../utils/date';

// Redux
import { getInterviewRoundScheduleDetails } from '../../redux/actions/interviews';
import { renderHrAvatar, wordCapitalize } from '../../utils/common';

// Packages
import { Badge } from 'primereact/badge';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';

const ScheduleDetails = ({ show, interviewRound, onHide }) => {
  const dispatch = useDispatch();

  // Store
  const { scheduleDetails } = useSelector((state) => state.interviews);

  // State
  const [interviewScheduleDetails, setInterviewScheduleDetails] = useState([]);

  useEffect(() => {
    dispatch(getInterviewRoundScheduleDetails(interviewRound));
  }, []);

  useEffect(() => {
    if (scheduleDetails && scheduleDetails?.length) {
      const data = scheduleDetails.map((item) => {
        return {
          ...item,
          'Interviewer Name': item?.InvitationHistory?.length
            ? item.InvitationHistory.map(
                (interviewer) =>
                  `${wordCapitalize(
                    interviewer?.Invitation?.Invited?.FirstName || ''
                  )} ${wordCapitalize(interviewer?.Invitation?.Invited?.LastName || '')}`
              ).join(', ')
            : '',
          'Start Date': `${getFormattedDateView(item.StartDateOfInterviewRound)} ${formatTime(
            item?.StartDateOfInterviewRound
          )}`,
          HR: renderHrAvatar(
            item?.Round?.HROfRound?.ID,
            item?.Round?.HROfRound?.UserProfile,
            item?.Round?.HROfRound?.FirstName,
            item?.Round?.HROfRound?.LastName
          ),
          InterviewRound: `${INTERVIEW_TYPE.filter((num) => num.code === item?.InterviewRound).map(
            (type) => type.name
          )}`,
          Status: INTERVIEW_STATUS_LIST.filter((num) => num.code === item?.Status).map(
            (type, index) => {
              return (
                <div key={index} className="center interview-status">
                  <Badge
                    key={type.code}
                    className={`white-space-nowrap ${
                      item?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_SCHEDULE ||
                      item?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_SCHEDULE ||
                      item?.Status === STATUS_INTERVIEW.MANAGEMENT_SCHEDULE ||
                      item?.Status === STATUS_INTERVIEW.PRACTICAL_SCHEDULE ||
                      item?.Status === STATUS_INTERVIEW.HR_SCHEDULE
                        ? 'schedule-badge'
                        : item?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_RESCHEDULE ||
                          item?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_RESCHEDULE ||
                          item?.Status === STATUS_INTERVIEW.MANAGEMENT_RESCHEDULE ||
                          item?.Status === STATUS_INTERVIEW.HR_RESCHEDULE ||
                          item?.Status === STATUS_INTERVIEW.PRACTICAL_RESCHEDULE
                        ? 'reschedule-badge'
                        : item?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_COMPLETE ||
                          item?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_COMPLETE ||
                          item?.Status === STATUS_INTERVIEW.MANAGEMENT_COMPLETE ||
                          item?.Status === STATUS_INTERVIEW.PRACTICAL_COMPLETE ||
                          item?.Status === STATUS_INTERVIEW.HR_COMPLETE
                        ? 'complete-badge'
                        : item?.Status === STATUS_INTERVIEW.CANCELLED
                        ? 'cancel-badge'
                        : 'pending-badge'
                    }`}
                    value={type.name}
                  />
                </div>
              );
            }
          )
        };
      });

      setInterviewScheduleDetails(data);
    }
  }, [scheduleDetails]);

  return (
    <Dialog
      header="Schedule Details"
      visible={show}
      onHide={onHide}
      className="notifications-modal">
      <DataTable
        isHeaderDisable
        data={interviewScheduleDetails}
        columns={INTERVIEW_ROUND_SCHEDULE_DETAILS}
        disablePaginator={true}
      />
    </Dialog>
  );
};

export default ScheduleDetails;
