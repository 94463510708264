export const ROLES = {
  ADMIN: 1,
  HR: 2,
  INTERVIEWER: 3,
  CANDIDATE: 4,
  HR_AND_INTERVIEWER: 5
};

export const STATUS = {
  PENDING: 1,
  ACCEPT: 2,
  REJECT: 3,
  ACCEPT_BY_OTHER: 4,
  AUTO_REJECT: 5,
  RESCHEDULED: 6
};

export const RENDER = {
  Yes: true,
  No: false
};

export const TINY_MCE_PLUGINS = [
  'advlist',
  'autolink',
  'lists',
  'link',
  'image',
  'charmap',
  'preview',
  'anchor',
  'searchreplace',
  'visualblocks',
  'code',
  'fullscreen',
  'insertdatetime',
  'media',
  'table',
  'code',
  'help',
  'wordcount'
];

export const INTERVIEW_INVITATION_STATUS = {
  1: 'Pending',
  2: 'Accepted',
  3: 'Rejected',
  4: 'Accepted by Others',
  5: 'Auto Rejected',
  6: 'Rescheduled'
};

export const Interview_Invitations_Status = {
  Pending: 1,
  Accepted: 2,
  Rejected: 3,
  'Accepted by Others': 4,
  'Auto Rejected': 5,
  Rescheduled: 6
};

export const ROUTE_PREFIX_FROM_ROLE = {
  1: 'ADMIN',
  2: 'HR',
  3: 'INTERVIEWER'
};

export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token',
  RESET_PASSWORD_WITHOUT_TOKEN: '/reset-password',
  // USER_PROFILE: '/profile',
  USER_PASSWORD: '/user/password',
  ADMIN: {
    DASHBOARD: '/admin/dashboard',
    USER_PROFILE: '/admin/profile',
    INTERVIEWERS: '/admin/interviewers',
    INTERVIEWER_ADD: '/admin/interviewers/add',
    INTERVIEWER_EDIT: '/admin/interviewers/:ID',
    HRS: '/admin/HRs',
    HR_ADD: '/admin/hrs/add',
    HR_EDIT: '/admin/hrs/:ID',
    CANDIDATES: '/admin/candidates',
    DESIGNATION: '/admin/jobdesignations',
    DESIGNATION_ADD: '/admin/designation/add',
    DESIGNATION_EDIT: '/admin/designation/:ID',
    TECHNOLOGY: '/admin/technologies',
    TECHNOLOGY_ADD: '/admin/technology/add',
    TECHNOLOGY_EDIT: '/admin/technology/:ID',
    DESCRIPTIONS: '/admin/jobdescriptions',
    COMMON_PARAMETERS: '/admin/feedback-parameters'
  },
  HR: {
    DASHBOARD: '/hr/dashboard',
    USER_PROFILE: '/hr/profile',
    CANDIDATES: '/hr/candidates',
    CANDIDATES_ADD: '/hr/candidates/add',
    CANDIDATES_EDIT: '/hr/candidates/:ID',
    INTERVIEWS: '/hr/interviews',
    INTERVIEW_ROUNDS: '/hr/interviews/:ID',
    INTERVIEW_ROUND: '/hr/interviews',
    INTERVIEWS_EDIT: '/hr/interviews/:ID',
    INTERVIEWS_ADD: '/hr/interviews/add',
    INTERVIEWS_ROUND_EDIT_ID: '/hr/interview-rounds/edit/:ID',
    INTERVIEWS_ROUND_EDIT: '/hr/interview-rounds/edit',
    INTERVIEWS_ROUND_ADD: '/hr/interview-rounds/add',
    INTERVIEWS_ROUND_ADD_ID: '/hr/interview-rounds/add/:addingID',
    INTERVIEW_SCHEDULE: '/hr/interviewers',
    DESIGNATION: '/hr/designation',
    DESIGNATION_ADD: '/hr/designation/add',
    DESIGNATION_EDIT: '/hr/designation/:ID',
    TECHNOLOGY: '/hr/technology',
    TECHNOLOGY_ADD: '/hr/technology/add',
    TECHNOLOGY_EDIT: '/hr/technology/:ID'
  },
  INTERVIEWER: {
    DASHBOARD: '/interviewer/dashboard',
    USER_PROFILE: '/interviewer/profile',
    SCHEDULED_INTERVIEW: '/interviewer/scheduled_interview',
    AVAILABILITY: '/interviewer/availability',
    INTERVIEW_INVITATION: '/interviewer/invitations',
    INTERVIEW_SCHEDULED: '/interviewer/interviews',
    INTERVIEW_HISTORY: '/interviewer/history'
  },
  NOT_ALLOWED: '/not-allowed',
  NOT_FOUND: '*'
};

export const API_ERROR_MESSAGES = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden Error',
  404: 'Not found',
  500: 'Internal server error',
  503: 'Service unavilable',
  default: 'Something went wrong !'
};

export const RESPONSE_STATUS_CODES = {
  UPDATE: 202,
  SUCCESS: 204,
  OK: 200,
  CREATED: 201,
  SESSION_EXPIRED: 419,
  TOKEN_EXPIRED: 440,
  UNAUTHORIZED: 401,
  FORBIDDEN_ERROR: 403
};

export const STORAGE_KEY = '!m$@uth';
export const KEEP_SIGN_IN_KEY = 'keepSignIn';

export const SECRET_KEY = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const SCRIPT_ID = 'external-script';
export const ANCHOR_ID = 'atlwdg-trigger';

export const JIRA_TOOL_SRC =
  'https://tatvasoft-team-c7nx0iof.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-3ddrgv/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=cd0280b9';

export const SUPPORTED_FORMATS = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

export const TECHNICAL_FEEDBACK_PARAMETERS = ['Front-end', 'Back-end', 'Database'];

export const USER_TYPE = {
  CANDIDATES: 'CANDIDATES',
  HRS: 'HRS',
  INTERVIEWERS: 'INTERVIEWERS'
};

export const INTERVIEW_TYPE = [
  { name: 'Technical Interview - 1', code: 1 },
  { name: 'Technical Interview - 2', code: 4 },
  { name: 'Management Round', code: 5 },
  { name: 'Practical', code: 2 },
  { name: 'HR Round', code: 3 }
];

export const INTERVIEW_MODE = [
  { name: 'Tatvasoft Office', code: 1 },
  { name: 'Rajkot Office', code: 2 },
  { name: 'Online', code: 3 }
];

export const INTERVIEW_MODE_LIST = {
  CALENDER_VIEW: 'calender',
  LIST: 'list'
};

export const InterviewMode = {
  'Tatvasoft Office': 1,
  'Rajkot Office': 2,
  Online: 3
};

export const InterviewType = {
  'Technical Interview - 1': 1,
  Practical: 2,
  'HR Round': 3,
  'Technical Interview - 2': 4,
  'Management Round': 5
};

export const CANDIDATE_LOCATIONS = [
  { name: 'Gujarat : Ahmedabad', code: 'Gujarat : Ahmedabad' },
  { name: 'Gujarat : Bhavnagar', code: 'Gujarat : Bhavnagar' },
  { name: 'Gujarat : Jamnagar', code: 'Gujarat : Jamnagar' },
  { name: 'Gujarat : Junagadh', code: 'Gujarat : Junagadh' },
  { name: 'Gujarat : Rajkot', code: 'Gujarat : Rajkot' },
  { name: 'Gujarat : Surat', code: 'Gujarat : Surat' },
  { name: 'Gujarat : Vadodara', code: 'Gujarat : Vadodara' },
  { name: 'Gujarat : Gandhinagar', code: 'Gujarat : Gandhinagar' },
  { name: 'Madhya Pradesh : Indore', code: 'Madhya Pradesh : Indore' },
  { name: 'Madhya Pradesh : Bhopal', code: 'Madhya Pradesh : Bhopal' },
  { name: 'Rajasthan : Udaipur', code: 'Rajasthan : Udaipur' },
  { name: 'Rajasthan : Jaipur', code: 'Rajasthan : Jaipur' },
  { name: 'Other', code: 'other' }
];

export const CANDIDATE_SOURCE_TYPE = [
  { name: 'Manual', code: 1 },
  { name: 'Naukri', code: 2 },
  { name: 'LinkedIn', code: 3 },
  { name: 'Reference', code: 4 }
];
export const INTERVIEW_STATUS_HISTORY = [
  { name: 'Completed', code: 3 },
  { name: 'Completed', code: 6 },
  { name: 'Completed', code: 9 },
  { name: 'Cancelled', code: 10 },
  { name: 'Completed', code: 16 },
  { name: 'Completed', code: 20 }
];

export const INTERVIEW_STATUS_LIST = [
  { name: 'Technical Interview - 1 Pending', code: 1 },
  { name: 'Technical Interview - 1 Schedule', code: 2 },
  { name: 'Technical Interview - 1 Reschedule', code: 11 },
  { name: 'Technical Interview - 1 Complete', code: 3 },
  { name: 'Technical Interview - 2 Pending', code: 13 },
  { name: 'Technical Interview - 2 Schedule', code: 14 },
  { name: 'Technical Interview - 2 Reschedule', code: 15 },
  { name: 'Technical Interview - 2 Complete', code: 16 },
  { name: 'Practical Pending', code: 4 },
  { name: 'Practical Schedule', code: 5 },
  { name: 'Practical Reschedule', code: 12 },
  { name: 'Practical Complete', code: 6 },
  { name: 'Management Pending', code: 17 },
  { name: 'Management Schedule', code: 18 },
  { name: 'Management Reschedule', code: 19 },
  { name: 'Management Complete', code: 20 },
  { name: 'HR Pending', code: 7 },
  { name: 'HR Schedule', code: 8 },
  { name: 'HR Reschedule', code: 21 },
  { name: 'HR Complete', code: 9 },
  { name: 'Cancelled', code: 10 }
];

export const INTERVIEW_STATUS = [
  { name: 'Technical Interview - 1', code: 1 },
  { name: 'Technical Interview - 1', code: 2 },
  { name: 'Technical Interview - 1', code: 11 },
  { name: 'Technical Interview - 1', code: 3 },
  { name: 'Technical Interview - 2', code: 13 },
  { name: 'Technical Interview - 2', code: 14 },
  { name: 'Technical Interview - 2', code: 15 },
  { name: 'Technical Interview - 2', code: 16 },
  { name: 'Practical', code: 4 },
  { name: 'Practical', code: 5 },
  { name: 'Practical', code: 12 },
  { name: 'Practical', code: 6 },
  { name: 'Management', code: 17 },
  { name: 'Management', code: 18 },
  { name: 'Management', code: 19 },
  { name: 'Management', code: 20 },
  { name: 'HR', code: 7 },
  { name: 'HR', code: 8 },
  { name: 'HR', code: 21 },
  { name: 'HR', code: 9 },
  { name: 'Cancelled', code: 10 }
];

export const INTERVIEW_COLORS = {
  PENDING: '#F9A147',
  SCHEDULE: '#22B4BE',
  RESCHEDULE: '#208FC7',
  COMPLETE: '#46C96D',
  CANCEL: '#E76169'
};

export const INTERVIEW_BACKGROUND_COLORS = {
  PENDING: '#FFF1E2',
  SCHEDULE: '#DEFDFF',
  RESCHEDULE: '#DAF2FF',
  COMPLETE: '#E2F7E8',
  CANCEL: '#FFEEEF'
};

export const INTERVIEW_BACKGROUND_COLORS_DARK_MODE = {
  PENDING: '#4A2C0D',
  SCHEDULE: '#0F2C2D',
  RESCHEDULE: '#043852',
  COMPLETE: '#092F14',
  CANCEL: '#3A0C0F'
};

export const NOTIFICATION_TYPE = {
  INVITATION_SENT: '1',
  INVITATION_ACCEPTED: '2',
  INVITATION_REJECTED: '3',
  INVITATION__REMINDER: '4',
  INTERVIEW_SCHEDULE: '5',
  INTERVIEW_CANCEL: '6',
  INTERVIEW_RESCHEDULE: '7',
  FEEDBACK_REMINDER: '8',
  INVITATION_AUTO_REJECT: '9'
};

export const STATUS_INTERVIEW = {
  TECHNICAL_INTERVIEW_1_PENDING: 1,
  TECHNICAL_INTERVIEW_1_SCHEDULE: 2,
  TECHNICAL_INTERVIEW_1_COMPLETE: 3,
  PRACTICAL_PENDING: 4,
  PRACTICAL_SCHEDULE: 5,
  PRACTICAL_COMPLETE: 6,
  HR_PENDING: 7,
  HR_SCHEDULE: 8,
  HR_RESCHEDULE: 21,
  HR_COMPLETE: 9,
  CANCELLED: 10,
  TECHNICAL_INTERVIEW_1_RESCHEDULE: 11,
  PRACTICAL_RESCHEDULE: 12,
  TECHNICAL_INTERVIEW_2_PENDING: 13,
  TECHNICAL_INTERVIEW_2_SCHEDULE: 14,
  TECHNICAL_INTERVIEW_2_RESCHEDULE: 15,
  TECHNICAL_INTERVIEW_2_COMPLETE: 16,
  MANAGEMENT_PENDING: 17,
  MANAGEMENT_SCHEDULE: 18,
  MANAGEMENT_RESCHEDULE: 19,
  MANAGEMENT_COMPLETE: 20
};

// Using separate constant value then interview round due to different status values.
export const PARENT_INTERVIEW_STATUS_LIST = [
  { name: 'Pending', code: 1 },
  { name: 'Completed', code: 2 },
  { name: 'Cancelled', code: 3 }
];

export const PARENT_STATUS_INTERVIEW = {
  PENDING: 1,
  COMPLETED: 2,
  CANCELLED: 3
};

export const DURATION = [
  { name: '30 Min', code: 30 },
  { name: '1 Hour', code: 60 },
  { name: '1.5 Hour', code: 90 },
  { name: '2 Hour', code: 120 },
  { name: '2.5 Hour', code: 150 }
];

export const INTERVIEW_CATEGORY = [
  { name: 'Technical', code: 1 },
  { name: 'Non Technical', code: 0 }
];

export const Interview_Category = {
  Technical: 1,
  'Non Technical': 0
};

export const EXPORT_TABLE = {
  EXCEL_TYPE: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  FORMAT: '.xlsx'
};

export const BUTTON_TYPES = {
  LOGIN: 'Login',
  SUBMIT: 'Submit',
  GO_BACK: 'Go Back',
  GO_BACK_HOME: ' Back to Home ',
  SEND: 'Send',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  UPLOAD_RESUME: 'Upload Resume',
  UPDATE_RESUME: 'Update Resume',
  UPLOAD_CANDIDATE_IMAGE: 'Upload Candidate Image',
  UPDATE_CANDIDATE_IMAGE: 'Update Candidate Image',
  SAVE_RESCHEDULE: 'Save & Reschedule',
  UPDATE_PASSWORD: 'Update Password',
  SAVE_EXIT: 'Save & Exit',
  ADD_NEW_SLOT: 'Add New Slot'
};

export const LABEL_TYPES = {
  EMAIL: 'Email Address',
  USERNAME: 'Username',
  PASSWORD: 'Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  OLD_PASSWORD: 'Old Password',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  MOBILE: 'Mobile Number',
  SECONDARY_MOBILE: 'Second Mobile Number',
  GENDER: 'Gender',
  MALE: 'Male',
  FEMALE: 'Female',
  YEAR_EXPERIENCE: 'Exp. in Years',
  MONTH_EXPERIENCE: 'Exp. in Months',
  SCHEDULE_INTERVIEW: 'Want to schedule interview?',
  START_DATE: 'Interview Start Date',
  START_TIME: 'Interview Start Time',
  END_DATE: 'Interview End Date',
  END_TIME: 'Interview End Time',
  INTERVIEW_TYPE: 'Interview Type',
  INTERVIEW_ROUND_MODE: 'Interview Round Mode',
  DESIGNATION: 'Designation',
  JOB_DESCRIPTION_NAME: 'Name',
  JOB_DESCRIPTION_SUBJECT: 'Subject',
  TECHNOLOGY: 'Technology',
  TECHNICAL_SKILLS: 'Technical skills',
  PRACTICAL_ROUND: 'Will take Practical Round?',
  PRACTICAL_ADMIN: 'Is Admin of Practical Team?',
  PARAMETER: 'Name',
  IS_GROUPED: 'Make Parameter Group',
  SUB_PARAMETERS: 'Add Sub Parameters',
  INTERVIEW_DURATION: 'Interview duration',
  INTERVIEWER_LIMIT: 'Number of Interviewers',
  INTERVIEWERS: 'Select Interviewers',
  FEEDBACK_PARAMETERS: 'Select Feedback Parameters',
  DESCRIPTION: 'Description',
  UPLOAD_RESUME: 'Upload Resume',
  UPDATE_RESUME: 'Update Resume',
  ADDRESS: 'Address',
  SOFT_SKILLS: 'Soft Skills',
  TECHNOLOGIES: 'Technologies',
  FROM_TIME: 'From Time',
  TO_TIME: 'To Time',
  CANDIDATE: 'Select Candidate',
  TECHSTACK: 'TechStack',
  PACKAGE: 'Average Annual Salary',
  CANDIDATE_EXPECTATION: 'Candidate Salary Expectation',
  NOTIFICATION: 'Notification',
  COLOR_PICKER: 'Pick Color',
  CANCEL_REASON: 'Cancel Reason',
  CURRENT_COMPANY: 'Current Company',
  CURRENT_CTC: 'Current CTC',
  DATE_OF_BIRTH: 'Data of Birth',
  CANDIDATE_CURRENT_LOCATION: 'Candidate Current Location',
  CANDIDATE_SOURCE: 'Candidate Source',
  INTERVIEW_CATEGORY: 'Interview Category',
  CANDIDATE_SOURCE_REFERENCE: 'Reference Name'
};

export const MSG_TYPES = {
  NO_RESULT: 'No results found'
};

export const INITIAL_PAGE_NUMBER = 1;

export const INITIAL_SIZE = 100;

export const APLHABET_PATTERN_REGEX = /^[a-z|A-Z|\s]+$/;

export const EMAIL_PATTERN_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const NOTIFICATION_PERMISSION_MSG = 'Please allow notification permission';

export const DEBOUNCE_TIMEOUT = 500;

export const PERMISSIONS = {
  GRANTED: 'granted',
  DENIED: 'denied',
  DEFAULT: 'default'
};

export const FCM_TOKEN = 'FCM_TOKEN';

export const NOT_ALLOWED_SYMBOLS = ['e', 'E', '+', '-', '.'];

export const OVERALL_FEEDBACK = [
  { name: 'Pass', code: '1' },
  { name: 'Fail', code: '2' }
];

export const OVERALL_FEEDBACK_STATUS = {
  1: 'Pass',
  2: 'Fail'
};
// Function to compare values
export function compareValues(obj, value) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (obj[key] === value) {
        return key;
      }
    }
  }
  return null; // If the value is not found in the object
}

export const HR_OVERALL_FEEDBACK = [
  { name: 'Selected', code: '1' },
  { name: 'Not Selected', code: '2' },
  { name: 'Offered', code: '3' },
  { name: 'In Discussion', code: '4' },
  { name: 'Offer Rejected', code: '5' },
  { name: 'Offer Accepted', code: '6' }
];

export const HR_OVERALL_FEEDBACK_STATUS = {
  Selected: '1',
  'Not Selected': '2',
  Offered: '3',
  'In Discussion': '4',
  'Offer Rejected': '5',
  'Offer Accepted': '6'
};

export const OVERALL_GRADE = [
  { name: 'A+', code: '1' },
  { name: 'A', code: '2' },
  { name: 'A-', code: '3' },
  { name: 'B+ to A-', code: '9' },
  { name: 'B+', code: '4' },
  { name: 'B to B+', code: '10' },
  { name: 'B', code: '5' },
  { name: 'B- to B', code: '11' },
  { name: 'B-', code: '6' },
  { name: 'C', code: '7' },
  { name: 'N/A', code: '8' }
];

export const OVERALL_GRADE_STATUS = {
  1: 'A+',
  2: 'A',
  3: 'A-',
  4: 'B+',
  5: 'B',
  6: 'B-',
  7: 'C',
  9: 'B+ to A-',
  10: 'B to B+',
  11: 'B- to B',
  8: 'N/A'
};

export const PRACTICAL_RESULT = [
  { name: 'Selected', code: '1' },
  { name: 'Not Selected', code: '2' },
  { name: 'Further Review', code: '3' }
];

export const TECHNICAL_RESULT = [
  { name: 'Selected', code: '1' },
  { name: 'Not Selected', code: '2' }
];

export const PRACTICAL_RESULT_STATUS = {
  1: 'Selected',
  2: 'Not Selected',
  3: 'Further Review'
};

export const CODE_QUALITY = [
  { name: 'Poor', code: '1' },
  { name: 'Good', code: '2' },
  { name: 'Great', code: '3' }
];

export const CODE_QUALITY_STATUS = {
  1: 'Poor',
  2: 'Good',
  3: 'Great'
};

export const ACTION_TYPE = {
  VIEW: 'view',
  EDIT: 'edit',
  DELETE: 'delete',
  COMPLETE: 'complete',
  ASSIGN_TO_OTHER: 'assigneToOther',
  ACCEPT: 'accept',
  REJECT: 'reject',
  FEEDBACK: 'feedback',
  SCHEDULE_DETAILS: 'scheduleDetails',
  NOTES: 'notes',
  AVAILABILITY: 'avaibility',
  SEND_JD: 'sendjd',
  CANCEL: 'cancel'
};

export const TABLE_NAME = {
  INTERVIEWERS: 'Interviewers_List',
  HR: 'HR_List',
  DESIGNATION: 'Job_Designation',
  JOB_DESCRIPTION: 'Job_Description',
  TECHNOLOGY: 'Technologies',
  CANDIDATE: 'Candidates_List',
  SCHEDULED_INTERVIEWS: 'Scheduled_Interviews',
  INTERVIEW_ROUNDS: 'Interview_Rounds',
  INTERVIEW: 'Interviews_List',
  INTERVIEW_HISTORY: 'Interview_History',
  INTERVIEW_INVITATIONS: 'Interview_Invitations',
  SCHEDULE_DETAILS: 'Schedule Details',
  COMMON_PRAMETERS: 'Common_Parameters'
};

export const USER_TYPE_TEXT = {
  CANDIDATES: 'Candidates',
  HRS: 'HRs',
  INTERVIEWERS: 'Interviewers',
  PLANED_INTERVIEWS: 'Interviews'
};

export const MOMENT_FORMATS = {
  DATE_ONLY: 'YYYY-MM-DD',
  DD_MM_YYYY: 'DD/MM/YYYY'
};

export const TEXTAREA_LIMIT = {
  MIN: process.env.REACT_APP_TEXTAREA_MIN_LIMIT || 30,
  MAX: process.env.REACT_APP_TEXTAREA_MAX_LIMIT || 300
};

export const FEEDBACK_TEXTAREA_LIMIT = {
  MIN: process.env.REACT_APP_TEXTAREA_MIN_LIMIT || 30,
  MAX: 1000
};

export const APP_VERSION = '23.06.14';

export const Calendar_Messages = {
  today: 'Today',
  previous: 'Previous',
  next: 'Next',
  month: 'Month',
  week: 'Week',
  day: 'Day'
};

export const eventStyleGetter = (event) => {
  const style = {
    backgroundColor: event.backgroundColor, // Background color
    borderColor: event.color //border color
  };

  return {
    style
  };
};

export const TooltipContent = ({ event }) => {
  return (
    <>
      <div>
        <strong>Job Title :</strong> {event.jobTitle || '--'}
      </div>
      <div>
        <strong>Interviewer :</strong> {event.interviewer || '--'}
      </div>
      <div>
        <strong>Interview Level :</strong> {event.interviewLevel || '--'}
      </div>
      <div>
        <strong>Interview Mode :</strong> {event.interviewMode || '--'}
      </div>
      <div>
        <strong>Scheduled By :</strong> {event.scheduleBy || '--'}
      </div>
    </>
  );
};

export const queryParamsOfFeedbackParams = {
  searchValue: '',
  pageNumber: INITIAL_PAGE_NUMBER,
  size: 10000,
  sortBy: 'DateModified',
  orderBy: 'desc'
};
