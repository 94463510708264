import api from '../../utils/axios';

// Constants
import { API_ROUTES } from '../../constants/apiRoutes';

export const interviewersListApi = async ({
  pageNumber,
  searchValue,
  sortBy,
  orderBy,
  technology,
  size,
  practicalOptionFilter
}) => {
  try {
    let url = `${API_ROUTES.INTERVIEWER_LIST}?page=${pageNumber}&searchName=${searchValue}&technologies=${technology}&sortBy=${sortBy}&orderBy=${orderBy}&size=${size}`;

    if (
      practicalOptionFilter &&
      Array.isArray(practicalOptionFilter) &&
      practicalOptionFilter.length > 0
    ) {
      practicalOptionFilter.forEach((item) => {
        url += `&${encodeURIComponent(item)}=true`;
      });
    }
    const response = await api.get(url);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const interviewerAddApi = async (interviewerDetails) => {
  try {
    const response = await api.post(API_ROUTES.INTERVIEWER_ADD, interviewerDetails);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const interviewerDeleteApi = async (interviewerId) => {
  try {
    const response = await api.delete(`${API_ROUTES.USER_DETAILS}/${interviewerId}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const interviewerScheduledApi = async (timePeriod = 'Today', size = 5) => {
  try {
    const result = await api.get(
      `${API_ROUTES.INTERVIEW_SCHEDULED}?size=${size}&timePeriod=${timePeriod}`
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
export const interviewerInvitationApi = async (size = 5) => {
  try {
    const response = await api.get(
      `${API_ROUTES.INTERVIEW_INVITATIONS}?size=${size}&invitationStatus=1`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const interviewerPendingFeedbackApi = async (size = 5) => {
  try {
    const response = await api.get(`${API_ROUTES.INTERVIEW_PENDING_FEEDBACK}?size=${size}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const editInterviewerApi = async (details) => {
  try {
    const response = await api.put(
      `${API_ROUTES.USER_DETAILS}/${details.InterviewerId}`,
      details.formData
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateMobileApi = async (details) => {
  try {
    const response = await api.put(
      `${API_ROUTES.UPDATE_MOBILE_NUMBER}/${details.userID}`,
      details.postData
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const userStatusApi = async (payload) => {
  try {
    const response = await api.put(`${API_ROUTES.USER_STATUS}/${payload.id}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const interviewersAvailabilityApi = async (id) => {
  try {
    let apiRoute = API_ROUTES.INTERVIEWER_AVAILABILITY;
    if (id) {
      apiRoute = `${API_ROUTES.INTERVIEWER_AVAILABILITY}/${id}`;
    }
    const response = await api.get(apiRoute);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const interviewerAddAvailbilityApi = async (availbility) => {
  try {
    let apiRoute = API_ROUTES.INTERVIEWER_AVAILABILITY;
    if (availbility?.id) {
      apiRoute = `${API_ROUTES.INTERVIEWER_AVAILABILITY}/${availbility?.id}`;
    }
    const response = await api.post(apiRoute, { availability: availbility?.availability });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
