import React, { useEffect, useRef, useState } from 'react';
import { EqualHeightElement } from 'react-equal-height';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// Prime-React Components
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-datalabels';

// Components
import { CustomeTooltip } from './ActionButtons';

// Redux-Actions
import { getTechnologyWithFilter } from '../redux/actions/admin';

// Constants
import {
  INTERVIEW_BACKGROUND_COLORS_DARK_MODE,
  INTERVIEW_COLORS,
  INTERVIEW_STATUS_LIST
} from '../constants/common';

// Images
import filterIc from '@assets/images/filter-ic.svg';
import filterIcDark from '@assets/images/filter-ic-dark.svg';

// Utils
import { getDateWithDay } from '../utils/date';
import { getBarOptions } from '../utils/chart';
import { isDarkMode, wordCapitalize } from '../utils/common';

const PieChart = () => {
  const dispatch = useDispatch();
  const { interviewRoundData, pieChartFilterdData } = useSelector((state) => state.adminDashboard);
  const scheduleInterviewCount = useSelector((state) => state.hrs.scheduleInterviewCount);

  const durationFilterRef = useRef(null);
  const hrFilterRef = useRef(null);
  const [roundStatusCode, setRoundStatusCode] = useState({});
  const [filterStatus, setStatusFilter] = useState('day');
  const [hrData, setHrData] = useState([]);
  const [selectedHR, setSelectedHR] = useState('');
  const [chartData, setChartData] = useState([]);

  function modifiedHrData(data) {
    const scheduleInterviews = data || [];
    let labels = [
      {
        label: 'All',
        value: '',
        command: () => setSelectedHR(''),
        className: selectedHR === '' ? 'highlighted-item' : ''
      }
    ];

    scheduleInterviews.forEach((hr) => {
      let hrName = `${wordCapitalize(hr?.FirstName || '')} ${wordCapitalize(hr.LastName || '')}`;
      labels.push({
        label: hrName,
        value: hr?.ID,
        command: () => setSelectedHR(hr?.ID),
        className: selectedHR === hr?.ID ? 'highlighted-item' : ''
      });
    });
    setHrData(labels);
  }

  useEffect(() => {
    modifiedHrData(scheduleInterviewCount);
  }, [scheduleInterviewCount]);

  useEffect(() => {
    let data = (interviewRoundData?.length !== 0 ? interviewRoundData : pieChartFilterdData) || [];
    const modifiedInterviewRoundStatus = data.map((item) => {
      return {
        HR: {
          ...item.HROfRound
        },
        Status: item.Status
      };
    });

    setChartData(modifiedInterviewRoundStatus);
  }, [interviewRoundData, pieChartFilterdData]);

  useEffect(() => {
    const modifiedInterviewRoundStatus = (pieChartFilterdData || []).map((item) => {
      return {
        HR: {
          ...item.HROfRound
        },
        Status: item.Status
      };
    });

    setChartData(modifiedInterviewRoundStatus);
  }, [pieChartFilterdData]);

  const interviewStatusObject = () => {
    return INTERVIEW_STATUS_LIST.reduce((obj, item) => {
      obj[item.name] = 0;
      return obj;
    }, {});
  };

  const getInterviewRoundName = (status) => {
    return INTERVIEW_STATUS_LIST.filter((item) => item.code === status)[0]?.name;
  };

  const generateLabelForChart = () => {
    return INTERVIEW_STATUS_LIST.map((status) => {
      const parts = status.name.split(' ');
      const first = parts.shift(); // First word
      const last = parts.pop(); // Last word
      const middle = parts.join(' '); // Middle part
      return [first, middle, last].filter((part) => part !== '' && part !== undefined);
    });
  };

  // for interview status
  function filterData(data) {
    if (!data?.length) {
      return { statusData: [] };
    } else {
      let statusCodeData = interviewStatusObject();
      data.forEach((round) => {
        if (selectedHR) {
          if (selectedHR === round?.HR?.ID) {
            let roundName = getInterviewRoundName(round.Status);
            const count =
              statusCodeData[roundName] !== undefined ? statusCodeData[roundName] + 1 : 0;
            statusCodeData[roundName] = count;
            setRoundStatusCode(statusCodeData);
          }
        } else {
          let roundName = getInterviewRoundName(round.Status);
          const count = statusCodeData[roundName] !== undefined ? statusCodeData[roundName] + 1 : 0;
          statusCodeData[roundName] = count;
          setRoundStatusCode(statusCodeData);
        }
      });
      setRoundStatusCode(statusCodeData);
    }
  }

  useEffect(() => {
    filterData(chartData);
    modifiedHrData(scheduleInterviewCount);
  }, [interviewRoundData, chartData, selectedHR]);

  useEffect(() => {
    filterData(chartData);
    modifiedHrData(scheduleInterviewCount);
  }, [pieChartFilterdData, chartData, selectedHR]);

  useEffect(() => {
    var startOfWeek = getDateWithDay(moment().startOf(filterStatus).toDate());
    var endOfWeek = getDateWithDay(moment().endOf(filterStatus).toDate());

    if (filterStatus) {
      dispatch(getTechnologyWithFilter(startOfWeek, endOfWeek));
    }
  }, [dispatch, filterStatus]);

  const statusFilter = [
    {
      label: 'Today',
      value: 1,
      command: () => setStatusFilter('day'),
      className: filterStatus === 'day' ? 'highlighted-item' : ''
    },
    {
      label: 'Weekly',
      value: 1,
      command: () => setStatusFilter('week'),
      className: filterStatus === 'week' ? 'highlighted-item' : ''
    },
    {
      label: 'Monthly',
      value: 2,
      command: () => setStatusFilter('month'),
      className: filterStatus === 'month' ? 'highlighted-item' : ''
    }
  ];

  const darkModeColors = [
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.PENDING,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.SCHEDULE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.RESCHEDULE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.COMPLETE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.PENDING,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.SCHEDULE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.RESCHEDULE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.COMPLETE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.PENDING,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.SCHEDULE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.RESCHEDULE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.COMPLETE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.PENDING,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.SCHEDULE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.RESCHEDULE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.COMPLETE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.PENDING,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.SCHEDULE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.RESCHEDULE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.COMPLETE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.CANCEL
  ];

  const lightModeColors = [
    INTERVIEW_COLORS.PENDING,
    INTERVIEW_COLORS.SCHEDULE,
    INTERVIEW_COLORS.RESCHEDULE,
    INTERVIEW_COLORS.COMPLETE,
    INTERVIEW_COLORS.PENDING,
    INTERVIEW_COLORS.SCHEDULE,
    INTERVIEW_COLORS.RESCHEDULE,
    INTERVIEW_COLORS.COMPLETE,
    INTERVIEW_COLORS.PENDING,
    INTERVIEW_COLORS.SCHEDULE,
    INTERVIEW_COLORS.RESCHEDULE,
    INTERVIEW_COLORS.COMPLETE,
    INTERVIEW_COLORS.PENDING,
    INTERVIEW_COLORS.SCHEDULE,
    INTERVIEW_COLORS.RESCHEDULE,
    INTERVIEW_COLORS.COMPLETE,
    INTERVIEW_COLORS.PENDING,
    INTERVIEW_COLORS.SCHEDULE,
    INTERVIEW_COLORS.RESCHEDULE,
    INTERVIEW_COLORS.COMPLETE,
    INTERVIEW_COLORS.CANCEL
  ];

  const statusChartData = {
    labels: generateLabelForChart(),
    datasets: [
      {
        data: INTERVIEW_STATUS_LIST.map((status) => roundStatusCode[status.name]),
        borderWidth: 0,
        borderRadius: 50,
        barThickness: 20,
        backgroundColor: isDarkMode() ? darkModeColors : lightModeColors,
        datalabels: {
          color: '#FFF'
        }
      }
    ]
  };

  return (
    <div className="white-box-item full-width">
      <div className="white-box">
        <div className="card-title-block">
          <EqualHeightElement name="title">
            <div className="card-title-wrapper">
              <span className="card-title">Interview status</span>
              <i
                className="custom-target-icon pi pi-info-circle pl-1"
                data-tip={`${
                  filterStatus === 'week'
                    ? "Last week's"
                    : filterStatus === 'month'
                    ? "Last month's"
                    : "Today's"
                } Interviews based on Status`}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </EqualHeightElement>
          <div className="filter-block">
            <Menu
              model={statusFilter}
              popup
              ref={durationFilterRef}
              id="popup_menu"
              appendTo="self"
              className="popup_menu action-popup chartFilterMenu"
            />
            <Menu
              model={hrData}
              popup
              ref={hrFilterRef}
              id="popup_menu"
              appendTo="self"
              className="popup_menu action-popup chartFilterMenu hr-name-list"
            />
            <CustomeTooltip />
            <div className="space-between">
              <Button
                data-tip="Duration Filter"
                type="button"
                onClick={(event) => durationFilterRef.current.toggle(event)}
                aria-controls="popup_menu"
                aria-haspopup
                className="p-button-rounded p-button-text border-none primary-icon-btn filter-btn mr-1">
                <i>
                  <img src={filterIc} className="default-img" alt="filter" />
                  <img src={filterIcDark} className="dark-mode-img" alt="filter" />
                </i>
              </Button>
              <Button
                data-tip="Filter By HR"
                type="button"
                onClick={(event) => hrFilterRef.current.toggle(event)}
                aria-controls="popup_menu"
                aria-haspopup
                className="p-button-rounded p-button-text border-none primary-icon-btn filter-btn">
                <i>
                  <img src={filterIc} className="default-img" alt="filter" />
                  <img src={filterIcDark} className="dark-mode-img" alt="filter" />
                </i>
              </Button>
            </div>
          </div>
        </div>

        <div className="chart-container">
          <div className="chart-figure-wrapper" style={{ width: '2000px' }}>
            <Chart
              type="bar"
              data={statusChartData}
              options={getBarOptions()}
              plugins={[ChartDataLabels]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChart;
