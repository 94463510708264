import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

// PrimeReact-Components
import { TabView, TabPanel } from 'primereact/tabview';
import { InputSwitch } from 'primereact/inputswitch';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';

// Components
import CustomButton from '../../components/CustomButton';
import Label from '../../components/Label';
import DatePicker from 'react-datepicker';

// Constants
import { AVAILABILITY_DAYS } from '../../constants/interviewer';
import { BUTTON_TYPES, LABEL_TYPES } from '../../constants/common';

// Redux-Actions
import {
  addInterviewersAvailbility,
  getInterviewersAvailbility
} from '../../redux/actions/interviewers';

function InterviewerAvailability(props) {
  const { interviewerId } = props;

  const dayStart = '08:00';
  const dayEnd = '20:00';
  const dispatch = useDispatch();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [repeatAllDays, setRepeatAllDays] = useState(false);
  const [showWarningPopup, toggleWarningPopup] = useState(false);
  const [minTime, setMinTime] = useState(null);
  const [maxTime, setMaxTime] = useState(null);
  const [timeValidationError, setTimeValidationError] = useState('');
  const { interviewers } = useSelector((state) => state);
  const { isLoading } = useSelector((state) => state.apiStatus);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);

  useEffect(() => {
    dispatch(getInterviewersAvailbility(interviewerId));
  }, [dispatch]);

  useEffect(() => {
    let setData = [];
    if (interviewers?.availability?.length) {
      setData = interviewers?.availability?.map((data) => {
        const formattedTimeSlots = data?.timeSlots?.map((timeSlot) => ({
          from: timeSlot.from.slice(0, 5), // Extracts the "HH:mm" part
          to: timeSlot.to.slice(0, 5) // Extracts the "HH:mm" part
        }));

        return {
          ...data,
          day: data.day,
          timeSlots: formattedTimeSlots
        };
      });
    }

    setAvailableTimeSlots(setData);
  }, [interviewers]);

  useEffect(() => {
    setMinTime(timeToMinutes(dayStart));
    setMaxTime(timeToMinutes(dayEnd));
  }, [minTime, maxTime]);

  const onSaveTimeSlot = () => {
    dispatch(addInterviewersAvailbility({ id: interviewerId, availability: availableTimeSlots }));
  };

  const onAddNewTimeSlot = (day) => {
    let isValid = true;

    availableTimeSlots.forEach((slot) => {
      if (!isValid) return; // If the flag is false, exit the loop immediately
      if (day === slot.day && slot.timeSlots?.length) {
        slot.timeSlots.forEach((timeSlot) => {
          if (!isValid) return; // If the flag is false, exit the loop immediately
          if (!timeSlot.from || !timeSlot.to) {
            toast.error(
              'Please add information for the previously added time slot before adding a new one.'
            );
            isValid = false;
          }
        });
      }
    });
    if (!isValid) return;
    if (repeatAllDays) {
      setAvailableTimeSlots((prevState) => {
        const newTimeSlots = [...prevState].map((slot) => {
          const timeSlots = [...slot.timeSlots, { from: '', to: '' }];
          return { ...slot, timeSlots };
        });
        return newTimeSlots;
      });
    } else {
      setAvailableTimeSlots((prevState) => {
        const newTimeSlots = [...prevState].map((slot) => {
          if (slot.day === day) {
            const timeSlots = [...slot.timeSlots, { from: '', to: '' }];
            return { ...slot, timeSlots };
          }
          return slot;
        });
        return newTimeSlots;
      });
    }
  };

  const onRemoveTimeSlot = ({ day, timeSlotIndex }) => {
    if (repeatAllDays) {
      setAvailableTimeSlots((prevState) => {
        const newTimeSlots = [...prevState].map((slot) => {
          const timeSlots = [...slot.timeSlots].filter(
            (timeSlot, index) => index !== timeSlotIndex
          );
          return { ...slot, timeSlots };
        });
        return newTimeSlots;
      });
    } else {
      setAvailableTimeSlots((prevState) => {
        const newTimeSlots = [...prevState].map((slot) => {
          if (slot.day === day) {
            const timeSlots = [...slot.timeSlots].filter(
              (timeSlot, index) => index !== timeSlotIndex
            );
            return { ...slot, timeSlots };
          }
          return slot;
        });
        return newTimeSlots;
      });
    }
  };

  const timeToMinutes = (time) => {
    time = time.split(':');
    const totalMinutes = Number(time[0]) * 60 + Number(time[1]);
    return totalMinutes;
  };

  const onCheckTimeValidation = ({ day, from, to, timeSlotIndex }) => {
    const newFromTime = timeToMinutes(from);
    const newToTime = timeToMinutes(to);

    if (newToTime < newFromTime) {
      setTimeValidationError('To-Time must be greater than From-Time');
      toggleWarningPopup(true);
      return false;
    } else {
      const daySlot = availableTimeSlots.find((availableSlot) => availableSlot.day === day);
      const timeSlots = daySlot.timeSlots.map((timeSlot) => ({
        from: timeToMinutes(timeSlot.from),
        to: timeToMinutes(timeSlot.to)
      }));

      const ignoreToCompare = timeSlotIndex;

      const notAllowed = timeSlots.some(
        (timeSlot, index) =>
          index !== ignoreToCompare &&
          (newFromTime === timeSlot.from ||
            newFromTime === timeSlot.to ||
            newToTime === timeSlot.from ||
            newToTime === timeSlot.to ||
            (newFromTime > timeSlot.from && newFromTime < timeSlot.to) ||
            (newToTime > timeSlot.from && newToTime < timeSlot.to))
      );

      if (!notAllowed) {
        setTimeValidationError('');
        toggleWarningPopup(false);
        return true;
      } else {
        // setTimeValidationError('This time slot already exists');
        // toggleWarningPopup(true);
        return true;
      }
    }
  };

  function validateTimeSlots(schedule) {
    let isValid = true;

    // Function to check if two time ranges overlap
    function doTimeRangesOverlap(range1, range2) {
      if (!range1.from || !range1.to || !range2.from || !range2.to) {
        return false;
      }

      const parseTime = (time) => new Date(`2000-01-01 ${time}`);
      const start1 = parseTime(range1.from);
      const end1 = parseTime(range1.to);
      const start2 = parseTime(range2.from);
      const end2 = parseTime(range2.to);

      return (start1 >= start2 && end1 <= end2) || (start1 <= start2 && end1 >= end2);
    }

    // Loop through each day in the schedule
    schedule.forEach((day) => {
      const timeSlots = day.timeSlots;
      if (timeSlots.length > 1) {
        for (let i = 0; i < timeSlots.length - 1; i++) {
          for (let j = i + 1; j < timeSlots.length; j++) {
            if (
              doTimeRangesOverlap(timeSlots[i], timeSlots[j]) ||
              (timeSlots[i].from === timeSlots[j].from && timeSlots[i].to === timeSlots[j].to) ||
              timeSlots[i].from === timeSlots[i].to ||
              timeSlots[j].from === timeSlots[j].to
            ) {
              isValid = false;
              break;
            }
          }
          if (!isValid) break;
        }
      } else if (timeSlots.length === 1) {
        if (timeSlots[0].from === timeSlots[0].to || timeSlots[0].from === timeSlots[0].to) {
          isValid = false;
        }
      }
    });

    return isValid;
  }

  const updateSingleTimeSlot = (timeSlots, timeSlotIndex, { from, to }) =>
    timeSlots.map((timeSlot, index) => (timeSlotIndex === index ? { from, to } : timeSlot));

  const updateTimeSlotsForDay = (timeSlot, day, timeSlotIndex, { from, to }) =>
    timeSlot.day === day
      ? {
          ...timeSlot,
          timeSlots: updateSingleTimeSlot(timeSlot.timeSlots, timeSlotIndex, { from, to })
        }
      : timeSlot;

  const onSetTime = ({ day, timeSlotIndex, from, to }) => {
    const updatedTimeSlots = availableTimeSlots.map((timeSlot) =>
      updateTimeSlotsForDay(timeSlot, day, timeSlotIndex, { from, to })
    );

    if (!validateTimeSlots(updatedTimeSlots)) {
      toast.error('One or more time slots overlap. Please ensure there are no conflicts.');
      return;
    }

    if (onCheckTimeValidation({ day, from, to, timeSlotIndex })) {
      setAvailableTimeSlots((prevState) =>
        prevState.map((timeSlot) =>
          repeatAllDays || timeSlot.day === day
            ? updateTimeSlotsForDay(timeSlot, day, timeSlotIndex, { from, to })
            : timeSlot
        )
      );
    }
  };

  const onTabChange = (tabIndex) => {
    setRepeatAllDays(false);
    setActiveTabIndex(tabIndex);
  };

  const onToggleSwitch = ({ event, day }) => {
    if (event.value) {
      confirmDialog({
        header: 'Repeat',
        target: event.currentTarget,
        message: 'Save with Repeat all days?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          setRepeatAllDays(event.value);
          const slot = availableTimeSlots.find((slot) => slot.day === day);
          const newTimeSlots = AVAILABILITY_DAYS.map((day) => {
            return {
              day: day.name,
              timeSlots: slot.timeSlots
            };
          });
          setAvailableTimeSlots(newTimeSlots);
          dispatch(addInterviewersAvailbility({ id: interviewerId, availability: newTimeSlots }));
        }
      });
    } else {
      setRepeatAllDays(event.value);
    }
  };

  return (
    <>
      <ConfirmPopup />
      <Dialog
        className="rounded overflow-hidden"
        header="Alert"
        visible={showWarningPopup}
        style={{ width: '300px', top: '-90px' }}
        onHide={() => toggleWarningPopup((prevState) => !prevState)}>
        <p>{timeValidationError}</p>
      </Dialog>
      <div className="white-box p-0">
        <TabView
          activeIndex={activeTabIndex}
          scrollable={true}
          onTabChange={(e) => onTabChange(e.index)}
          className="availiblity-tabbing custom-tabbing">
          {availableTimeSlots.map((availableSlot, index) => (
            <TabPanel key={availableSlot.day} header={availableSlot.day}>
              {availableSlot.timeSlots?.map((timeSlot, innerIndex) => {
                return (
                  <div key={`${index}-${innerIndex}`}>
                    <div
                      key={`${index}-${innerIndex}`}
                      className="flex align-items-end availiblity-form-row">
                      <div className="availiblity-form-col">
                        <div className="custom-form-group custom-timepicker">
                          <Label htmlFor="from" text={LABEL_TYPES.FROM_TIME} isMandatory />
                          <DatePicker
                            id="time"
                            selected={
                              timeSlot?.from ? moment(timeSlot?.from, 'HH:mm:ss').toDate() : ''
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            onChange={(e) => {
                              onSetTime({
                                day: availableSlot.day,
                                timeSlotIndex: innerIndex,
                                from: e ? moment(e).format('HH:mm') : '',
                                to: timeSlot?.to
                              });
                            }}
                            minTime={new Date().setHours(0, 0, 0)}
                            maxTime={new Date().setHours(23, 59, 59)}
                          />
                        </div>
                      </div>
                      <div className="availiblity-form-col">
                        <div className="custom-form-group custom-timepicker">
                          <Label htmlFor="to" text={LABEL_TYPES.TO_TIME} isMandatory />
                          <DatePicker
                            id="time"
                            selected={timeSlot?.to ? moment(timeSlot?.to, 'HH:mm:ss').toDate() : ''}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            onChange={(e) => {
                              onSetTime({
                                day: availableSlot.day,
                                timeSlotIndex: innerIndex,
                                from: timeSlot?.from,
                                to: e ? moment(e).format('HH:mm') : ''
                              });
                            }}
                            minTime={new Date().setHours(0, 0, 0)}
                            maxTime={new Date().setHours(23, 59, 59)}
                          />
                        </div>
                      </div>

                      <div className="availiblity-form-col btn-col">
                        <CustomButton
                          data-tip="Delete"
                          onClick={() =>
                            onRemoveTimeSlot({
                              day: availableSlot.day,
                              timeSlotIndex: innerIndex
                            })
                          }
                          className="primary-link delete-slot-btn">
                          <i className="pi pi-trash"></i>
                        </CustomButton>
                      </div>
                    </div>
                  </div>
                );
              })}
              <CustomButton
                variant="contained"
                className="add-user-btn add-slot-btn"
                onClick={() => onAddNewTimeSlot(availableSlot.day)}>
                + ADD MORE
              </CustomButton>

              <div className="flex align-items-center mt-2">
                <CustomButton
                  variant="contained"
                  onClick={() => onSaveTimeSlot()}
                  disabled={isLoading}>
                  {BUTTON_TYPES.SAVE}
                </CustomButton>
                <div className="flex align-items-center switch-wrapper">
                  <label htmlFor="" className="mx-2">
                    Repeat for all days
                  </label>
                  <InputSwitch
                    data-tip="Repeat all days"
                    checked={repeatAllDays}
                    onChange={(e) => onToggleSwitch({ event: e, day: availableSlot.day })}
                    disabled={isLoading}
                  />
                </div>
              </div>
            </TabPanel>
          ))}
        </TabView>
      </div>
    </>
  );
}
export default InterviewerAvailability;
