import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

//Constants
import {
  INTERVIEWS_ADD_REQUEST,
  INTERVIEWS_LIST_REQUEST,
  INTERVIEW_FEEDBACK_LIST_REQUEST,
  INTERVIEW_INVITATION_LIST_REQUEST,
  INTERVIEW_INVITATION_STATUS,
  INTERVIEW_SCHEDULE_LIST_REQUEST,
  INTERVIEW_GET_DETAILS,
  INTERVIEW_EDIT_REQUEST,
  INTERVIEW_HISTORY_LIST_REQUEST,
  INTERVIEW_STATUS,
  INTERVIEW_FEEDBACK,
  INTERVIEWS_ADD_ROUND_REQUEST,
  INTERVIEWS_EDIT_ROUND_REQUEST,
  INTERVIEWS_EDIT_REQUEST,
  INTERVIEWS_TECHPARAMS_REQUEST,
  GET_HR_FEEDBACK_HISTORY_LOGS,
  COMPLETE_INTERVIEW_FEEDBACK_REQUEST,
  INTERVIEW_CANCEL_REQUEST,
  INTERVIEW_ROUND_CANCEL_REQUEST,
  CANCEL_CANDIDATE_INTERVIEW_ROUND,
  INTERVIEW_ROUND_SCHEDULE_DETAILS
} from '../../constants/redux';
import { InterviewType, RESPONSE_STATUS_CODES } from '../../constants/common';

// Api-Services
import {
  addInterviewsApi,
  getInterviewsListApi,
  interviewPendingFeedbackListApi,
  getInterviewDetailsApi,
  editInterviewApi,
  interviewHistoryListApi,
  addFeedbackApi,
  editInterviewRoundApi,
  addInterviewRoundApi,
  editInterviewsApi,
  getTechparemsApi,
  getHrFeedbackHistoryLogAPi,
  updateFeedbackApi,
  cancelInterviewsAPI,
  cancelInterviewRoundsAPI,
  rejectCandidateInterviewRoundsAPI,
  getInterviewRoundScheduleDetailsApi
} from '../apis/interviews';

import {
  interviewInitationStatusApi,
  interviewInvitationListApi,
  interviewScheduleListApi,
  interviewStatusApi
} from '../apis/interviews';

// Redux-Actions
import { addInterviewInvitations } from '../slices/interviewInvitation';
import {
  getInterviews,
  getInterviewDetails,
  redirectionOnStatusChange,
  getTecharams,
  getHistoryLogs,
  scheduleDetails,
  cancelInterview,
  cancelInterviewRound
} from '../slices/interviews';
import { addScheduleInterview } from '../slices/interviewSchedule';
import { apiSuccess } from '../slices/apiStatus';
import { addInterviewPendingFeedback } from '../slices/feedbackPending';
import { addInterviewHistory } from '../slices/interviewHistory';
import {
  getFeedbackText,
  getUpdateFeedbackText,
  intervieweFeedbackDetails,
  isFeedbackUpdated,
  updateHasHRAddedFeedback
} from '../slices/userDetails';
import { interviewerFeedbackDetailApi } from '../apis/userDetails';
import { getUserNotificationsAction } from '../actions/profileDetails';

function* getInterviewsList(action) {
  try {
    const response = yield call(getInterviewsListApi, { ...action.payload });
    const result = response.data.data;
    const data = result.interviews;
    const pagination = result.pagination;
    yield put(getInterviews({ data, pagination, tableStates: action.payload }));
  } catch (error) {
    console.log(error);
  }
}

function* addInterviews(action) {
  try {
    const response = yield call(addInterviewsApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      toast.success('Interview Added Succesfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
      yield put(
        redirectionOnStatusChange({
          redirection: true
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* interviewSheduledListSaga(action) {
  try {
    const response = yield call(interviewScheduleListApi, { ...action.payload });

    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      const data = result.scheduleInterview;
      const pagination = result.pagination;
      yield put(addScheduleInterview({ data, pagination }));
    }
  } catch (error) {
    throw new Error(error);
  }
}

function* interviewInvitaionsListSaga(action) {
  const response = yield call(interviewInvitationListApi, { ...action.payload });

  if (response?.status === RESPONSE_STATUS_CODES.OK) {
    const result = response.data.data;
    const data = result.interviewInvitations;
    const pagination = result.pagination;
    yield put(addInterviewInvitations({ data, pagination }));
  }
}
function* interviewPendingFeedbackListSaga(action) {
  const response = yield call(interviewPendingFeedbackListApi, { ...action.payload });

  if (response?.status === RESPONSE_STATUS_CODES.OK) {
    const result = response.data.data;
    const data = result.pendingFeedback;
    const pagination = result.pagination;
    yield put(addInterviewPendingFeedback({ data, pagination }));
  }
}
function* interviewInitationStatusSaga(action) {
  const response = yield call(interviewInitationStatusApi, action.payload);
  if (response?.status === RESPONSE_STATUS_CODES.SUCCESS) {
    yield put(apiSuccess());
    yield put(getUserNotificationsAction());
    yield put(
      redirectionOnStatusChange({
        redirection: true,
        invitationStatus: action.payload.invitationStatus
      })
    );
  }
}

function* interviewStatusSaga(action) {
  const response = yield call(interviewStatusApi, action.payload);
  if (response.status === RESPONSE_STATUS_CODES.SUCCESS) {
    yield put(apiSuccess());
  }
}

function* getInterviewDetailsSaga(action) {
  const interviewId = action.payload;
  const response = yield call(getInterviewDetailsApi, interviewId);
  if (response.status === RESPONSE_STATUS_CODES.OK) {
    const interviewDetailsData = response.data.data;
    yield put(getInterviewDetails(interviewDetailsData));
    yield put(apiSuccess());
  }
}

function* editInterviewSaga(action) {
  try {
    const response = yield call(editInterviewApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.OK) {
      toast.success('Details have been Updated Successfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
      yield put(
        redirectionOnStatusChange({
          redirection: true
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* editInterviewsSaga(action) {
  try {
    const response = yield call(editInterviewsApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.OK) {
      toast.success('Details have been Updated Successfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
      yield put(
        redirectionOnStatusChange({
          redirection: true
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* interviewHistoryListSaga(action) {
  try {
    const response = yield call(interviewHistoryListApi, { ...action.payload });

    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      const data = result.interviewHistory;
      const pagination = result.pagination;
      yield put(addInterviewHistory({ data, pagination }));
    }
  } catch (error) {
    throw new Error(error);
  }
}

function* addFeedback(action) {
  const interviewType = action.payload.interviewType;
  try {
    yield put(isFeedbackUpdated(true));
    const response = yield call(addFeedbackApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(isFeedbackUpdated(false));
      toast.success('Feedback Added Successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      var object = {};
      if (action.payload instanceof FormData) {
        action.payload.forEach(function (value, key) {
          object[key] = value;
        });
      } else {
        object = action.payload;
      }
      yield put(getFeedbackText(action.payload));
      const response = yield call(interviewerFeedbackDetailApi, object.interviewRoundId);
      if (response.status === RESPONSE_STATUS_CODES.OK) {
        const interviewDetailsData = response.data.data;
        yield put(intervieweFeedbackDetails(interviewDetailsData));
      }
      if (interviewType === InterviewType['HR Round']) {
        yield put(updateHasHRAddedFeedback(action.payload));
      }
      yield put(getUserNotificationsAction());
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* updateFeedback(action) {
  const interviewType = action.payload.interviewType;
  try {
    const response = yield call(updateFeedbackApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      toast.success('Feedback Added Successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      if (interviewType !== InterviewType['HR Round']) {
        yield put(getUpdateFeedbackText(action.payload));
      } else {
        yield put(updateHasHRAddedFeedback(action.payload));
      }
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* addInterviewRoundSaga(action) {
  try {
    const response = yield call(addInterviewRoundApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      toast.success('Interview round added successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
      yield put(
        redirectionOnStatusChange({
          redirection: true
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* editInterviewRoundSaga(action) {
  try {
    yield put(isFeedbackUpdated(true));
    const response = yield call(editInterviewRoundApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.OK) {
      yield put(isFeedbackUpdated(false));
      toast.success('Interview round updated successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
      yield put(
        redirectionOnStatusChange({
          redirection: true
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* getTechparamsSaga(action) {
  const interviewId = action.payload;
  const response = yield call(getTechparemsApi, interviewId);
  if (response.status === RESPONSE_STATUS_CODES.OK) {
    const techParamsData = response.data.data;
    yield put(getTecharams(techParamsData));
    yield put(apiSuccess());
  }
}

function* getInterviewroundScheduleDetailsSaga(action) {
  const interviewId = action.payload;
  const response = yield call(getInterviewRoundScheduleDetailsApi, interviewId);
  if (response.status === RESPONSE_STATUS_CODES.OK) {
    yield put(scheduleDetails(response.data.data));
    yield put(apiSuccess());
  }
}

function* getHrFeedbackHistoryLogSaga(action) {
  const response = yield call(getHrFeedbackHistoryLogAPi, action.payload);
  if (response.status === RESPONSE_STATUS_CODES.OK) {
    const data = response.data.data.interviewLogs;
    const pagination = response.data.data.pagination;
    yield put(getHistoryLogs({ data, pagination }));
  }
}

function* cancelInterviewSaga(action) {
  try {
    const response = yield call(cancelInterviewsAPI, action.payload);
    if ([RESPONSE_STATUS_CODES.OK, RESPONSE_STATUS_CODES.SUCCESS].indexOf(response?.status) > -1) {
      yield put(cancelInterview(action.payload.interviewId));
      toast.success('Interview Cancelled Successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* cancelInterviewRoundSaga(action) {
  try {
    const response = yield call(cancelInterviewRoundsAPI, action.payload);
    if ([RESPONSE_STATUS_CODES.OK, RESPONSE_STATUS_CODES.SUCCESS].indexOf(response?.status) > -1) {
      yield put(cancelInterviewRound(action.payload.interviewId));
      toast.success('Interview Cancelled Successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* rejectCandidateInterviewRoundSaga(action) {
  try {
    yield call(rejectCandidateInterviewRoundsAPI, action.payload);
    // const response = yield call(rejectCandidateInterviewRoundsAPI, action.payload);
    // if ([RESPONSE_STATUS_CODES.OK, RESPONSE_STATUS_CODES.SUCCESS].indexOf(response?.status) > -1) {
    //   yield put(cancelInterviewRound(action.payload.interviewId));
    //   toast.success('Interview Cancelled Successfully.', {
    //     position: toast.POSITION.BOTTOM_RIGHT
    //   });
    // }
  } catch (error) {
    console.log(error);
  }
}

export default function* watcherInterviewsSaga() {
  yield takeEvery(INTERVIEWS_LIST_REQUEST, getInterviewsList);
  yield takeEvery(INTERVIEWS_ADD_REQUEST, addInterviews);
  yield takeEvery(INTERVIEW_INVITATION_LIST_REQUEST, interviewInvitaionsListSaga);
  yield takeEvery(INTERVIEW_INVITATION_STATUS, interviewInitationStatusSaga);
  yield takeEvery(INTERVIEW_SCHEDULE_LIST_REQUEST, interviewSheduledListSaga);
  yield takeEvery(INTERVIEW_FEEDBACK_LIST_REQUEST, interviewPendingFeedbackListSaga);
  yield takeEvery(INTERVIEW_GET_DETAILS, getInterviewDetailsSaga);
  yield takeEvery(INTERVIEW_EDIT_REQUEST, editInterviewSaga);
  yield takeEvery(INTERVIEW_HISTORY_LIST_REQUEST, interviewHistoryListSaga);
  yield takeEvery(INTERVIEW_STATUS, interviewStatusSaga);
  yield takeEvery(INTERVIEW_FEEDBACK, addFeedback);
  yield takeEvery(COMPLETE_INTERVIEW_FEEDBACK_REQUEST, updateFeedback);
  yield takeEvery(INTERVIEWS_ADD_ROUND_REQUEST, addInterviewRoundSaga);
  yield takeEvery(INTERVIEWS_EDIT_ROUND_REQUEST, editInterviewRoundSaga);
  yield takeEvery(INTERVIEWS_EDIT_REQUEST, editInterviewsSaga);
  yield takeEvery(INTERVIEWS_TECHPARAMS_REQUEST, getTechparamsSaga);
  yield takeEvery(GET_HR_FEEDBACK_HISTORY_LOGS, getHrFeedbackHistoryLogSaga);
  yield takeEvery(INTERVIEW_CANCEL_REQUEST, cancelInterviewSaga);
  yield takeEvery(INTERVIEW_ROUND_CANCEL_REQUEST, cancelInterviewRoundSaga);
  yield takeEvery(CANCEL_CANDIDATE_INTERVIEW_ROUND, rejectCandidateInterviewRoundSaga);
  yield takeEvery(INTERVIEW_ROUND_SCHEDULE_DETAILS, getInterviewroundScheduleDetailsSaga);
}
