import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

// Prime-React Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { Badge } from 'primereact/badge';

// Redux-Actions
import {
  getInterviewersInvitation,
  getInterviewersSchedule
} from '../../redux/actions/interviewers';
import { interviewerInterviewDetailAction } from '../../redux/actions/userDetails';
import {
  cancelInterviewRoundAction,
  interviewInvitaionStatus
} from '../../redux/actions/interviews';

// Contants
import { INTERVIEWER } from '../../constants/tableColumns';
import {
  ACTION_TYPE,
  INTERVIEW_INVITATION_STATUS,
  Interview_Invitations_Status,
  INTERVIEW_MODE,
  INTERVIEW_TYPE,
  ROUTES,
  STATUS
} from '../../constants/common';

// Utils
import { formatTime, getFormattedDateView } from '../../utils/date';
import {
  downloadPdf,
  formatExperience,
  isDarkMode,
  openResumeLink,
  renderHrAvatar,
  setPreviousRoundDetails,
  wordCapitalize
} from '../../utils/common';

// Components
import {
  ActionButtonsForInvitationInterview,
  ActionButtonsForSceduledInterview,
  ResumeButton
} from '../../components/ActionButtons';
import AddEditFeedback from '../../components/popup/AddEditFeedback';
import InterviewDetails from '../../components/popup/InterviewDetails';
import CustomButton from '../../components/CustomButton';

// Redux-Slices
import { apiStatusClear } from '../../redux/slices/apiStatus';
import { redirectionOnStatusChange } from '../../redux/slices/interviews';
import { getNotification } from '../../redux/slices/notification';
import moment from 'moment';
import ConfirmDialogWithReason from '../../components/ConfirmDialogWithReasonForInterview';
import { getUserNotificationsAction } from '../../redux/actions/profileDetails';
import AssignToOtherModal from '../../components/popup/AssignToOtherInterview';

const InterviewerDashboardTable = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-wrap interviewer-table-header">
        <h5 className="flex align-items-center white-space-nowrap mr-2 mb-0">{props.tableName}</h5>
        <div className="flex justify-content-between align-items-center flex-1 mb-0 button-wrapper">
          <Badge value={`${props?.totalRecords || 0}`} className="white-space-nowrap"></Badge>
          <CustomButton
            variant="contained"
            onClick={() => navigate(props.redirect)}
            className="outline-btn">
            View all
          </CustomButton>
        </div>
      </div>
      <div className="white-box custom-table-wrapper p-0 overflow-hidden">
        <DataTable size="small" value={props.tableData} responsiveLayout="scroll">
          {props.tableColumns.map((column, index) => {
            return (
              <Column
                key={index}
                field={column.field}
                header={column.header}
                className={column.class}
              />
            );
          })}
        </DataTable>
      </div>
    </>
  );
};

const pathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/resume`;
const profilePathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/profile`;

const InterviewerDashboard = () => {
  const navigate = useNavigate();

  // Redux hooks.
  const dispatch = useDispatch();
  const { scheduleData, invitationData, feedbackData } = useSelector(
    (state) => state.interviewerDashboard
  );
  const { redirection, invitationStatus } = useSelector((state) => state.interviews);
  const { userDetails, auth } = useSelector((state) => state);
  const { isNotification } = useSelector((state) => state.notification);

  // React useState hooks.
  const [scheduleList, setScheduleList] = useState([]);
  const [invitationList, setInvitationList] = useState([]);
  const [interviewId, setInterviewId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [typeInterview, setTypeInterview] = useState(null);
  const [interviewRoundMode, setInterviewRoundMode] = useState(null);
  const [getCandidateName, setCandidateName] = useState('');
  const [getCandidateId, setCandidateId] = useState(null);
  const [invitationId, setInvitationId] = useState(null);
  const [interviewDate, setInterviewDate] = useState(null);
  const [interviewCategory, setInterviewCategory] = useState(null);
  const [interviewRoundId, setInterviewRoundId] = useState(null);
  const [cancelConfirmBoxVisibility, setCancelConfirmBoxVisibility] = useState(false);
  const [displayConfirmAction, setDisplayConfirmAction] = useState(false);
  const [isSelectedCandidateInviteAccepted, setIsSelectedCandidateInviteAccepted] = useState(false);
  const [currentRoundData, setCurrentRoundData] = useState({});
  const [previousRoundData, setPreviousRoundData] = useState({});
  const [interviewroundData, setInterviewroundData] = useState(null);
  const [assignToOtherModal, setAssignToOtherModal] = useState(false);
  const [isHoveredImage, setIsHoveredImage] = useState({});
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };
  const onToggleFeedbackPopup = (value) => setShowFeedbackPopup(value);
  const onTogglePopup = () => setShowPopup((prevState) => !prevState);

  const handleView = (ID, status) => {
    if (ID) {
      onTogglePopup();
      setInterviewId(ID);
    }
    setIsSelectedCandidateInviteAccepted(
      status === undefined || status === Interview_Invitations_Status.Accepted
    );
  };

  // React useEffect hooks.
  useEffect(() => {
    interviewId && dispatch(interviewerInterviewDetailAction(interviewId));
  }, [dispatch, interviewId]);

  const modifyInterviewDetails = useCallback(() => {
    if (userDetails?.interviewData?.Interview?.Candidate && userDetails?.interviewData?.HROfRound) {
      const currentRoundDatails = {
        'Interview Details': {
          'Start Date': `${getFormattedDateView(
            userDetails?.interviewData?.StartDateOfInterviewRound
          )} ${formatTime(userDetails?.interviewData?.StartDateOfInterviewRound || '')} `,
          'End Date': `${getFormattedDateView(
            userDetails?.interviewData?.EndDateOfInterviewRound
          )} ${formatTime(userDetails?.interviewData?.EndDateOfInterviewRound || '')} `,
          'Interview Type': `${INTERVIEW_TYPE.filter(
            (num) => num.code === userDetails?.interviewData?.InterviewRound
          ).map((type) => type.name)}`,
          Technology:
            userDetails?.interviewData?.Interview?.Candidate.UMap &&
            userDetails?.interviewData?.Interview?.Candidate.UMap.length > 0
              ? userDetails?.interviewData?.Interview?.Candidate.UMap?.map((tech) =>
                  wordCapitalize(tech.MapT.Technology)
                ).join(', ')
              : null,
          Interviewers:
            userDetails?.interviewData?.InterviewRoundMapping &&
            userDetails?.interviewData?.InterviewRoundMapping.length > 0
              ? userDetails?.interviewData?.InterviewRoundMapping?.map(
                  (tech) =>
                    `${wordCapitalize(tech.Examiner.FirstName)} ${wordCapitalize(
                      tech.Examiner.LastName
                    )} ${tech.Examiner.Email && `[${tech.Examiner.Email}]`}`
                ).join(', ')
              : null,
          'Interview Mode': userDetails?.interviewData?.InterviewRoundMode
            ? INTERVIEW_MODE.find(
                (num) => num.code === userDetails?.interviewData?.InterviewRoundMode
              )?.name
            : '--'
        },
        'Candidate Details': {
          'Candidate Name': `${
            wordCapitalize(userDetails?.interviewData?.Interview?.Candidate?.FirstName) || ''
          } ${wordCapitalize(userDetails?.interviewData?.Interview?.Candidate?.LastName) || ''}`,
          'Candidate Exp': formatExperience(
            userDetails?.interviewData?.Interview?.Candidate?.YearsOfExperience,
            userDetails?.interviewData?.Interview?.Candidate?.MonthsOfExperience
          )
        },
        Description: {
          Description: userDetails?.interviewData?.Description || '--'
        },
        'HR Details': {
          HR: renderHrAvatar(
            userDetails?.interviewData?.HROfRound?.ID,
            userDetails?.interviewData?.HROfRound?.UserProfile,
            userDetails?.interviewData?.HROfRound?.FirstName,
            userDetails?.interviewData?.HROfRound?.LastName,
            true
          )
        }
      };
      let previousRoundDetails = {};
      if (isSelectedCandidateInviteAccepted) {
        previousRoundDetails = setPreviousRoundDetails(userDetails);
      }
      setCurrentRoundData(currentRoundDatails);
      setPreviousRoundData(previousRoundDetails);
    }
  }, [userDetails, isSelectedCandidateInviteAccepted]);

  useEffect(() => {
    modifyInterviewDetails();
  }, [userDetails, modifyInterviewDetails]);

  useEffect(() => {
    if (interviewDate && invitationId) {
      setDisplayConfirmAction(true);
    } else {
      setDisplayConfirmAction(false);
    }
  }, [invitationId, interviewDate]);

  useEffect(() => {
    if (redirection) {
      setDisplayConfirmAction(false);
      if (invitationStatus == STATUS.ACCEPT) {
        navigate(ROUTES.INTERVIEWER.INTERVIEW_SCHEDULED);
        toast.success('Invitation Accepted Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        dispatch(apiStatusClear());
        dispatch(redirectionOnStatusChange({ redirection: false, invitationStatus }));
      } else {
        dispatch(redirectionOnStatusChange({ redirection: false, invitationStatus }));
      }
    }
  }, [redirection, navigate, dispatch]);

  useEffect(() => {
    const modifiedData = (interviews, tableName) =>
      interviews?.map((data, index) => {
        const { InterviewRound, Status, InvitationHistory } = data;
        const { Interview, ID, Description, StartDateOfInterviewRound } = InterviewRound;
        const candidateName = `${
          Interview?.Candidate?.FirstName ? wordCapitalize(Interview.Candidate?.FirstName) : ''
        } ${Interview?.Candidate?.LastName ? wordCapitalize(Interview.Candidate?.LastName) : ''}`;
        return {
          ...data,
          CandidateName: (
            <div className="flex" style={{ position: 'relative' }}>
              <Link to="#" className="table-view-popup-link" onClick={() => handleView(ID, Status)}>
                {candidateName}
              </Link>
              {Interview?.Candidate?.UserProfile && Interview?.Candidate?.ID && (
                <div
                  onMouseEnter={() => {
                    if (Interview?.Candidate?.UserProfile && Interview?.Candidate?.ID) {
                      let hoveredImage = { ...isHoveredImage, [Interview?.Candidate?.ID]: true };
                      setIsHoveredImage(hoveredImage);
                    }
                  }}
                  onMouseLeave={() => {
                    let hoveredImage = { ...isHoveredImage, [Interview?.Candidate?.ID]: false };
                    setIsHoveredImage(hoveredImage);
                  }}
                  onMouseMove={handleMouseMove}>
                  <i
                    onClick={() =>
                      openResumeLink(
                        `${profilePathBase}/${Interview?.Candidate?.ID}/${Interview?.Candidate?.UserProfile}`
                      )
                    }
                    className="pi pi-fw pi-eye"
                  />
                  {isHoveredImage[Interview?.Candidate?.ID] && (
                    <div
                      className="image-preview"
                      key={index}
                      style={{
                        top: `${mousePosition.y + 10}px`,
                        left: `${mousePosition.x + 10}px`
                      }}>
                      <img
                        src={`${profilePathBase}/${Interview?.Candidate?.ID}/${Interview?.Candidate?.UserProfile}`}
                        alt="Preview"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          ),
          HrName: renderHrAvatar(
            InterviewRound.HROfRound?.ID,
            InterviewRound.HROfRound?.UserProfile,
            InterviewRound?.HROfRound?.FirstName,
            InterviewRound.HROfRound?.LastName
          ),
          StartDateOfInterviewRound:
            Status === STATUS.RESCHEDULED
              ? InvitationHistory?.InterviewHistory?.StartDateOfInterviewRound
                ? `${getFormattedDateView(
                    InvitationHistory?.InterviewHistory?.StartDateOfInterviewRound
                  )} ${formatTime(InvitationHistory?.InterviewHistory?.StartDateOfInterviewRound)}`
                : ''
              : `${getFormattedDateView(InterviewRound.StartDateOfInterviewRound)} ${formatTime(
                  InterviewRound.StartDateOfInterviewRound
                )}`,
          InterviewType: (
            <ul className="technology-list" key={index}>
              {Interview?.Candidate.UMap && Interview?.Candidate?.UMap?.length > 0
                ? Interview.Candidate.UMap?.map((tech) => (
                    <li
                      key={tech.MapT.ID}
                      className="technology-badge"
                      style={{
                        backgroundColor: `${
                          isDarkMode() ? tech?.MapT?.DarkModeColor : tech?.MapT?.TechnologyColor
                        }`
                      }}>
                      {tech.MapT.Technology}
                    </li>
                  ))
                : []}
            </ul>
          ),
          JobDesignation:
            Interview?.JobDesignation && wordCapitalize(Interview?.JobDesignation?.Designation),
          RoundOfInterview: `${INTERVIEW_TYPE.filter(
            (num) => num.code === InterviewRound.InterviewRound
          ).map((type) => type.name)}`,
          Description: Description || '',
          Status: (
            <Badge
              key={Status}
              value={INTERVIEW_INVITATION_STATUS[Status]}
              className={`white-space-nowrap ${
                Status === STATUS.ACCEPT
                  ? 'complete-badge'
                  : Status === STATUS.ACCEPT_BY_OTHER || Status === STATUS.RESCHEDULED
                  ? 'reschedule-badge'
                  : Status === STATUS.PENDING
                  ? 'pending-badge'
                  : 'cancel-badge'
              }`}
            />
          ),
          resume: (
            <div className="flex">
              {Interview?.Candidate?.ResumeOfCandidate && (
                <>
                  <ResumeButton
                    onClick={() =>
                      openResumeLink(
                        `${pathBase}/${Interview?.Candidate?.ID}/${Interview?.Candidate?.ResumeOfCandidate?.FilePath}`
                      )
                    }
                  />
                  <i
                    data-tip="Download"
                    onClick={() =>
                      downloadPdf(
                        pathBase,
                        Interview?.Candidate?.ID,
                        Interview?.Candidate?.ResumeOfCandidate?.FilePath
                      )
                    }
                    className="pi pi-fw pi-download"
                  />
                </>
              )}
            </div>
          ),
          action: (
            <>
              {tableName === "Today's interviews" ? (
                <div className="flex align-items-center">
                  <ActionButtonsForSceduledInterview
                    onClick={(actionType) =>
                      handleRowActions(
                        actionType,
                        InterviewRound.ID,
                        InterviewRound.InterviewRoundMode,
                        InterviewRound?.InterviewRound,
                        candidateName,
                        InterviewRound?.Interview?.Candidate?.ID,
                        Interview?.InterviewCategory
                      )
                    }
                    disabled={moment(moment()).isBefore(
                      InterviewRound.StartDateOfInterviewRound,
                      'minute'
                    )}
                  />
                </div>
              ) : (
                ''
              )}

              {tableName === 'Interview Invitations' && Status === STATUS.PENDING ? (
                <div className="flex align-items-center">
                  <ActionButtonsForInvitationInterview
                    disabled={Status !== STATUS.PENDING}
                    assignToOther={auth?.isPracticalAdmin}
                    disabledAssignToOther={moment(moment()).isAfter(
                      StartDateOfInterviewRound,
                      'minute'
                    )}
                    onClick={(actionType, e) =>
                      handleInvitationRowActions(
                        actionType,
                        InterviewRound,
                        data.ID,
                        StartDateOfInterviewRound,
                        e
                      )
                    }
                  />
                </div>
              ) : (
                ''
              )}
            </>
          )
        };
      });
    setScheduleList(modifiedData(scheduleData?.scheduleInterview, "Today's interviews"));
    setInvitationList(modifiedData(invitationData?.interviewInvitations, 'Interview Invitations'));
  }, [scheduleData, invitationData, feedbackData, redirection, isHoveredImage, isDarkMode()]);

  useEffect(() => {
    dispatch(getInterviewersInvitation());
    dispatch(getInterviewersSchedule());
    dispatch(getUserNotificationsAction());
    if (isNotification) {
      dispatch(getNotification(false));
    }
  }, [dispatch, redirection, isNotification]);

  useEffect(() => {
    if (!interviewRoundId) {
      setCancelConfirmBoxVisibility(false);
    }
  }, [interviewRoundId]);

  const dashboardTables = [
    {
      name: "Today's interviews",
      data: scheduleList,
      columns: INTERVIEWER.SCHEDULED_INTERVIEW_COLUMNS,
      redirect: ROUTES.INTERVIEWER.INTERVIEW_SCHEDULED,
      totalRecords: scheduleData?.totalRecords
    },
    {
      name: 'Interview Invitations',
      data: invitationList,
      columns: INTERVIEWER.INTERVIEW_INVITATION_COLUMNS,
      redirect: ROUTES.INTERVIEWER.INTERVIEW_INVITATION,
      totalRecords: invitationData?.totalRecords
    }
  ];

  const handleRowActions = (
    actionType,
    rowId,
    mode,
    type,
    candidateName,
    candidateId,
    interviewCategory
  ) => {
    if (actionType === ACTION_TYPE.FEEDBACK) {
      setTypeInterview(type);
      setCandidateName(candidateName);
      setInterviewRoundMode(mode);
      setCandidateId(candidateId);
      setInterviewCategory(interviewCategory);
      if (rowId) {
        onToggleFeedbackPopup(true);
        setInterviewId(rowId);
      }
    } else if (actionType === ACTION_TYPE.CANCEL) {
      setCandidateName(candidateName);
      setInterviewRoundId(rowId);
      setCancelConfirmBoxVisibility(true);
    }
  };

  const handleInvitationRowActions = (actionType, InterviewRound, acceptRejectId, date) => {
    if (actionType === ACTION_TYPE.ACCEPT) {
      const interviewDate = `${getFormattedDateView(date)} at ${formatTime(date)}`;
      const dateOfInterview = `${getFormattedDateView(interviewDate)}`;
      const TodaysDate = `${getFormattedDateView(new Date())}`;
      if (TodaysDate === dateOfInterview) {
        confirmDialog({
          message: (
            <>
              This interview is scheduled for <strong>{interviewDate}</strong> (TODAY). Are you sure
              you want to accept?
            </>
          ),
          header: 'Accept',
          icon: 'pi pi-exclamation-triangle',
          accept: () =>
            dispatch(
              interviewInvitaionStatus({ id: acceptRejectId, invitationStatus: STATUS.ACCEPT })
            )
        });
      } else {
        confirmDialog({
          message: (
            <>
              This interview is scheduled for <strong>{interviewDate}</strong>. Are you sure you
              want to accept?
            </>
          ),
          header: 'Accept',
          icon: 'pi pi-exclamation-triangle',
          accept: () =>
            dispatch(
              interviewInvitaionStatus({ id: acceptRejectId, invitationStatus: STATUS.ACCEPT })
            )
        });
      }
    } else if (actionType === ACTION_TYPE.REJECT) {
      const interviewDate = `${getFormattedDateView(date)} at ${formatTime(date)}`;
      setInvitationId(acceptRejectId);
      setInterviewDate(interviewDate);
    } else if (actionType === ACTION_TYPE.ASSIGN_TO_OTHER) {
      setInterviewroundData(InterviewRound);
      setAssignToOtherModal(true);
    }
  };

  return (
    <>
      <AddEditFeedback
        id={interviewId}
        onHide={onToggleFeedbackPopup}
        show={showFeedbackPopup}
        interviewType={typeInterview}
        interviewRoundMode={interviewRoundMode}
        candidateId={getCandidateId || ''}
        interviewRoundId={interviewId}
        interviewCategory={interviewCategory}
        candidateName={getCandidateName}
        confirmDialogMessage={
          <>
            Are you sure you want to submit the feedback for the{' '}
            <strong>{getCandidateName ? wordCapitalize(getCandidateName) : ''}</strong>
            {''} candidate?
          </>
        }
      />

      {assignToOtherModal && (
        <AssignToOtherModal
          show={assignToOtherModal}
          interviewroundData={interviewroundData}
          onHide={() => setAssignToOtherModal(false)}
        />
      )}

      <InterviewDetails
        data={
          isSelectedCandidateInviteAccepted
            ? {
                'Current Round Details': currentRoundData,
                'Previous Round Details': previousRoundData
              }
            : currentRoundData
        }
        isTabView={isSelectedCandidateInviteAccepted}
        onHide={onTogglePopup}
        show={showPopup}
      />
      {cancelConfirmBoxVisibility && (
        <ConfirmDialogWithReason
          bodyTitle={
            <>
              Are you sure you want to cancel the interview for{' '}
              <strong> &nbsp;{getCandidateName}?</strong>
            </>
          }
          interviewId={interviewRoundId}
          dialogHeader="Cancel interview"
          setInterviewId={setInterviewRoundId}
          displayConfirmAction={cancelConfirmBoxVisibility}
          ishandleSuccess
          isReasonRequired
          onSuccess={(payload) => {
            dispatch(cancelInterviewRoundAction(payload));
            setTimeout(() => {
              dispatch(getInterviewersSchedule());
              setCancelConfirmBoxVisibility(false);
            }, 250);
          }}
        />
      )}
      {displayConfirmAction && (
        <ConfirmDialogWithReason
          bodyTitle={
            <span>
              This interview is scheduled for <strong>{interviewDate}</strong>{' '}
              {getFormattedDateView(new Date()) === getFormattedDateView(interviewDate)
                ? ' (TODAY)'
                : ''}
              . Are you sure you want to reject?
            </span>
          }
          interviewId={invitationId}
          dialogHeader="Reject Invitation"
          setInterviewId={setInvitationId}
          displayConfirmAction={displayConfirmAction}
          isReasonRequired
          handleSubmit={(values) => {
            dispatch(
              interviewInvitaionStatus({
                id: invitationId,
                rejectionReason: values.cancelReason,
                invitationStatus: STATUS.REJECT
              })
            );
          }}
        />
      )}
      {dashboardTables.map((table, index) => {
        return (
          <div className="card interviewer-dashboard-card " key={index}>
            <InterviewerDashboardTable
              tableName={table.name}
              tableColumns={table.columns}
              tableData={table.data}
              redirect={table.redirect}
              totalRecords={table.totalRecords}
            />
          </div>
        );
      })}
    </>
  );
};

export default InterviewerDashboard;
