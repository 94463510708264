import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import DataTable from '../../components/DataTable';
import InterviewDetails from '../../components/popup/InterviewDetails';
import AddEditFeedback from '../../components/popup/AddEditFeedback';
import { ActionButtonsForSceduledInterview, ResumeButton } from '../../components/ActionButtons';

// Redux-Actions
import { interviewerInterviewDetailAction } from '../../redux/actions/userDetails';
import {
  cancelInterviewRoundAction,
  getInterviewSheduleList
} from '../../redux/actions/interviews';

// Redux-Slices
import { getNotification } from '../../redux/slices/notification';

// Constants
import { SCHEDULED_INTERVIEW_COLUMNS } from '../../constants/tableColumns';
import {
  ACTION_TYPE,
  DEBOUNCE_TIMEOUT,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  INTERVIEW_TYPE,
  InterviewType,
  TABLE_NAME
} from '../../constants/common';

// Utils
import { formatTime, getFormattedDateView } from '../../utils/date';
import { debounce } from '../../utils/debounce';
import {
  wordCapitalize,
  downloadPdf,
  formatExperience,
  renderHrAvatar,
  isDarkMode,
  setPreviousRoundDetails,
  openResumeLink
} from '../../utils/common';
import moment from 'moment';
import AddHrFeedback from '../../components/popup/AddHrFeedback';
import ConfirmDialogWithReason from '../../components/ConfirmDialogWithReasonForInterview';

const pathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/resume`;
const profilePathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/profile`;

const InterviewSchedule = () => {
  // Redux hooks.
  const dispatch = useDispatch();
  const { interviewSchedule, userDetails } = useSelector((state) => state);
  const { isNotification } = useSelector((state) => state.notification);

  // React useState hooks.
  const [scheduleInterviewList, setScheduleInterviewList] = useState([]);
  const [scheduleInterviewExport, setScheduleInterviewExport] = useState([]);
  const [interviewRoundMode, setInterviewRoundMode] = useState(null);
  const [interviewId, setInterviewId] = useState(null);
  const [interviewDetails, setInterviewDetails] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [typeInterview, setTypeInterview] = useState(null);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [showHRFeedbackPopup, setShowHRFeedbackPopup] = useState(false);
  const [getCandidateName, setCandidateName] = useState('');
  const [getCandidateId, setCandidateId] = useState(null);
  const [interviewRoundId, setInterviewRoundId] = useState(null);
  const [cancelConfirmBoxVisibility, setCancelConfirmBoxVisibility] = useState(false);
  const [interviewCategory, setInterviewCategory] = useState(null);
  const [isHoveredImage, setIsHoveredImage] = useState({});
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const [tableStates, setTableStates] = useState({
    searchValue: '',
    pageNumber: INITIAL_PAGE_NUMBER,
    size: INITIAL_SIZE,
    sortBy: 'DateModified',
    orderBy: 'desc'
  });

  // React useEffect hooks.
  const onTogglePopup = () => setShowPopup((prevState) => !prevState);
  const onToggleFeedbackPopup = (data) => setShowFeedbackPopup(data);
  const onToggleHRFeedbackPopup = (data) => setShowHRFeedbackPopup(data);
  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  useEffect(() => {
    dispatch(getInterviewSheduleList(tableStates));
    if (isNotification) {
      dispatch(getNotification(false));
    }
  }, [dispatch, tableStates, isNotification]);

  useEffect(() => {
    const modifiedList = interviewSchedule?.data?.map((interview, index) => {
      const { InterviewRound } = interview;
      const { Interview, ID, Description, StartDateOfInterviewRound } = InterviewRound;
      const candidateName = `${
        Interview?.Candidate?.FirstName ? wordCapitalize(Interview?.Candidate?.FirstName) : ''
      } ${
        wordCapitalize(Interview?.Candidate?.LastName)
          ? wordCapitalize(Interview?.Candidate?.LastName)
          : ''
      }`;
      return {
        ...interview,
        CandidateName: (
          <div className="flex" style={{ position: 'relative' }}>
            <Link to="#" className="table-view-popup-link" onClick={() => handleView(ID)}>
              {candidateName}
            </Link>
            {Interview?.Candidate?.UserProfile && Interview?.Candidate?.ID && (
              <div
                onMouseEnter={() => {
                  if (Interview?.Candidate?.UserProfile && Interview?.Candidate?.ID) {
                    let hoveredImage = { ...isHoveredImage, [Interview?.Candidate?.ID]: true };
                    setIsHoveredImage(hoveredImage);
                  }
                }}
                onMouseLeave={() => {
                  let hoveredImage = { ...isHoveredImage, [Interview?.Candidate?.ID]: false };
                  setIsHoveredImage(hoveredImage);
                }}
                onMouseMove={handleMouseMove}>
                <i
                  onClick={() =>
                    openResumeLink(
                      `${profilePathBase}/${Interview?.Candidate?.ID}/${Interview?.Candidate?.UserProfile}`
                    )
                  }
                  className="pi pi-fw pi-eye"
                />
                {isHoveredImage[Interview?.Candidate?.ID] && (
                  <div
                    className="image-preview"
                    key={index}
                    style={{
                      top: `${mousePosition.y + 10}px`,
                      left: `${mousePosition.x + 10}px`
                    }}>
                    <img
                      src={`${profilePathBase}/${Interview?.Candidate?.ID}/${Interview?.Candidate?.UserProfile}`}
                      alt="Preview"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ),
        HrName: renderHrAvatar(
          InterviewRound.HROfRound?.ID,
          InterviewRound.HROfRound?.UserProfile,
          InterviewRound?.HROfRound?.FirstName,
          InterviewRound.HROfRound?.LastName
        ),
        'Start Date': `${getFormattedDateView(
          InterviewRound.StartDateOfInterviewRound
        )} ${formatTime(InterviewRound.StartDateOfInterviewRound)}`,
        Technology: (
          <ul className="technology-list" key={index}>
            {Interview?.Candidate?.UMap && Interview?.Candidate?.UMap?.length > 0
              ? Interview.Candidate.UMap?.map((tech) => (
                  <li
                    key={tech.MapT.ID}
                    style={{
                      backgroundColor: `${
                        isDarkMode() ? tech?.MapT?.DarkModeColor : tech?.MapT?.TechnologyColor
                      }`
                    }}
                    className="technology-badge">
                    {tech.MapT.Technology}
                  </li>
                ))
              : []}
          </ul>
        ),
        JobDesignation:
          Interview?.JobDesignation && wordCapitalize(Interview?.JobDesignation?.Designation),

        RoundOfInterview: `${INTERVIEW_TYPE.filter(
          (num) => num.code === InterviewRound?.InterviewRound
        ).map((type) => type.name)}`,
        Description: Description || '',
        resume: (
          <div className="flex">
            {Interview?.Candidate?.ResumeOfCandidate && (
              <>
                <ResumeButton
                  onClick={() =>
                    openResumeLink(
                      `${pathBase}/${Interview?.Candidate?.ID}/${Interview?.Candidate?.ResumeOfCandidate?.FilePath}`
                    )
                  }
                />
                <i
                  data-tip="Download"
                  onClick={() =>
                    downloadPdf(
                      pathBase,
                      Interview?.Candidate?.ID,
                      Interview?.Candidate?.ResumeOfCandidate?.FilePath
                    )
                  }
                  className="pi pi-fw pi-download"
                />
              </>
            )}
          </div>
        ),
        action: (
          <div className="flex align-items-center">
            <ActionButtonsForSceduledInterview
              onClick={(actionType) =>
                handleRowActions(
                  actionType,
                  InterviewRound.ID,
                  InterviewRound?.InterviewRoundMode,
                  InterviewRound?.InterviewRound,
                  candidateName,
                  InterviewRound?.Interview?.Candidate?.ID,
                  Interview?.InterviewCategory
                )
              }
              disabled={moment(moment()).isBefore(StartDateOfInterviewRound, 'minute')}
            />
          </div>
        )
      };
    });
    setScheduleInterviewList(modifiedList);
  }, [interviewSchedule, isHoveredImage, isDarkMode()]);

  useEffect(() => {
    const modifiedList = interviewSchedule?.data?.map((interview) => {
      const { InterviewRound } = interview;
      const { Interview } = InterviewRound;
      const name = `${
        Interview?.Candidate?.FirstName ? wordCapitalize(Interview.Candidate?.FirstName) : ''
      } ${Interview?.Candidate?.LastName ? wordCapitalize(Interview.Candidate?.LastName) : ''}`;
      const Type = Interview?.Candidate?.UMap?.length
        ? Interview.Candidate.UMap.map((item) => item.MapT.Technology).join(', ')
        : [];
      return {
        CandidateName: name,
        HrName: `${
          InterviewRound.HROfRound?.FirstName
            ? wordCapitalize(InterviewRound.HROfRound?.FirstName)
            : ''
        } ${
          InterviewRound.HROfRound?.LastName
            ? wordCapitalize(InterviewRound.HROfRound?.LastName)
            : ''
        }`,
        'Start Date': `${getFormattedDateView(
          InterviewRound.StartDateOfInterviewRound
        )} ${formatTime(InterviewRound.StartDateOfInterviewRound)}`,

        Technology: Type,

        JobDesignation:
          Interview?.JobDesignation && wordCapitalize(Interview?.JobDesignation?.Designation),

        RoundOfInterview: `${INTERVIEW_TYPE.filter(
          (num) => num.code === InterviewRound?.InterviewRound
        ).map((type) => type.name)}`
      };
    });
    setScheduleInterviewExport(modifiedList);
  }, [interviewSchedule]);

  const modifyInterviewDetails = useCallback(() => {
    if (userDetails?.interviewData?.Interview?.Candidate && userDetails?.interviewData?.HROfRound) {
      const previousRoundDetails = setPreviousRoundDetails(userDetails);

      setInterviewDetails({
        'Current Round Details': {
          'Interview Details': {
            'Start Date': `${getFormattedDateView(
              userDetails?.interviewData?.StartDateOfInterviewRound
            )} ${formatTime(userDetails?.interviewData?.StartDateOfInterviewRound || '')} `,
            'End Date': `${getFormattedDateView(
              userDetails?.interviewData?.EndDateOfInterviewRound
            )} ${formatTime(userDetails?.interviewData?.EndDateOfInterviewRound || '')} `,
            'Interview Type': `${INTERVIEW_TYPE.filter(
              (num) => num.code === userDetails?.interviewData?.InterviewRound
            ).map((type) => type.name)}`,
            Technology:
              userDetails?.interviewData?.Interview?.Candidate.UMap &&
              userDetails?.interviewData?.Interview?.Candidate.UMap.length > 0
                ? userDetails?.interviewData?.Interview?.Candidate.UMap?.map((tech) =>
                    wordCapitalize(tech.MapT.Technology)
                  ).join(', ')
                : null,
            Interviewers:
              userDetails?.interviewData?.InterviewRoundMapping &&
              userDetails?.interviewData?.InterviewRoundMapping.length > 0
                ? userDetails?.interviewData?.InterviewRoundMapping?.map(
                    (tech) =>
                      `${wordCapitalize(tech.Examiner.FirstName)} ${wordCapitalize(
                        tech.Examiner.LastName
                      )} ${tech.Examiner.Email && `[${tech.Examiner.Email}]`}`
                  ).join(', ')
                : null,
            'Job Designation': userDetails?.interviewData?.Interview?.JobDesignation
              ? userDetails?.interviewData?.Interview?.JobDesignation?.Designation
              : '--'
          },
          'Candidate Details': {
            'Candidate Name': `${
              wordCapitalize(userDetails?.interviewData?.Interview?.Candidate?.FirstName) || ''
            } ${wordCapitalize(userDetails?.interviewData?.Interview?.Candidate?.LastName) || ''}`,
            'Candidate Exp': formatExperience(
              userDetails?.interviewData?.Interview?.Candidate?.YearsOfExperience,
              userDetails?.interviewData?.Interview?.Candidate?.MonthsOfExperience
            )
          },
          Description: {
            Description: userDetails?.interviewData?.Description || '--'
          },
          'HR Details': {
            HR: renderHrAvatar(
              userDetails?.interviewData?.HROfRound?.ID,
              userDetails?.interviewData?.HROfRound?.UserProfile,
              userDetails?.interviewData?.HROfRound?.FirstName,
              userDetails?.interviewData?.HROfRound?.LastName,
              true
            )
          }
        },
        'Previous Round Details': previousRoundDetails
      });
    }
  }, [userDetails]);

  useEffect(() => {
    modifyInterviewDetails();
  }, [userDetails, modifyInterviewDetails]);

  useEffect(() => {
    if (!interviewRoundId) {
      setCancelConfirmBoxVisibility(false);
    }
  }, [interviewRoundId]);

  useEffect(() => {
    interviewId && dispatch(interviewerInterviewDetailAction(interviewId));
  }, [dispatch, interviewId]);

  const handlePagination = (pageNumber, size) =>
    setTableStates((prevState) => {
      return {
        ...prevState,
        pageNumber,
        size
      };
    });

  const handleSearch = (searchValue) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        searchValue
      };
    });
  };

  const handleSort = (sortBy) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        sortBy,
        orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
      };
    });
  };
  const handleView = (ID) => {
    if (ID) {
      onTogglePopup();
      setInterviewId(ID);
    }
  };

  const handleRowActions = (
    actionType,
    rowId,
    mode,
    type,
    candidateName,
    candidateId,
    interviewCategory
  ) => {
    if (actionType === ACTION_TYPE.FEEDBACK) {
      setCandidateName(candidateName);
      setTypeInterview(type);
      setInterviewRoundMode(mode);
      setCandidateId(candidateId);
      setInterviewCategory(interviewCategory);
      if (rowId) {
        setInterviewId(rowId);
        if (type === InterviewType['HR Round']) {
          onToggleHRFeedbackPopup(true);
        } else {
          onToggleFeedbackPopup(true);
        }
      }
    } else if (actionType === ACTION_TYPE.CANCEL) {
      setCandidateName(candidateName);
      setInterviewRoundId(rowId);
      setCancelConfirmBoxVisibility(true);
    }
  };

  return (
    <>
      <AddHrFeedback
        id={interviewId}
        onHide={onToggleHRFeedbackPopup}
        show={showHRFeedbackPopup}
        interviewType={typeInterview}
        ByInterviewer
        interviewCategory={interviewCategory}
        confirmDialogMessage={
          <>
            Are you sure you want to submit the feedback for the{' '}
            <strong>{getCandidateName ? wordCapitalize(getCandidateName) : ''}</strong> candidate?
          </>
        }
      />

      <AddEditFeedback
        id={interviewId}
        onHide={onToggleFeedbackPopup}
        show={showFeedbackPopup}
        interviewType={typeInterview}
        interviewRoundMode={interviewRoundMode}
        candidateId={getCandidateId || ''}
        interviewRoundId={interviewId}
        interviewCategory={interviewCategory}
        candidateName={getCandidateName}
        confirmDialogMessage={
          <>
            Are you sure you want to submit the feedback for the{' '}
            <strong>{getCandidateName ? wordCapitalize(getCandidateName) : ''}</strong>
            {''} candidate?
          </>
        }
      />

      {cancelConfirmBoxVisibility && (
        <ConfirmDialogWithReason
          bodyTitle={
            <>
              Are you sure you want to cancel the interview for{' '}
              <strong> &nbsp;{getCandidateName}?</strong>
            </>
          }
          interviewId={interviewRoundId}
          dialogHeader="Cancel interview"
          setInterviewId={setInterviewRoundId}
          displayConfirmAction={cancelConfirmBoxVisibility}
          ishandleSuccess
          isReasonRequired
          onSuccess={(payload) => {
            dispatch(cancelInterviewRoundAction(payload));
            setTimeout(() => {
              dispatch(getInterviewSheduleList(tableStates));
              setCancelConfirmBoxVisibility(false);
            }, 250);
          }}
        />
      )}
      <InterviewDetails data={interviewDetails} isTabView onHide={onTogglePopup} show={showPopup} />
      <DataTable
        name={TABLE_NAME.SCHEDULED_INTERVIEWS}
        columns={SCHEDULED_INTERVIEW_COLUMNS}
        data={scheduleInterviewList}
        exportData={scheduleInterviewExport}
        searchPlaceHolder="Search by Candidate name, Email and Mobile"
        totalRecords={interviewSchedule?.pagination?.totalRecords}
        onPagination={handlePagination}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        onSort={handleSort}
      />
    </>
  );
};

export default InterviewSchedule;
