import React, { useEffect, useRef, useState } from 'react';
import { EqualHeightElement } from 'react-equal-height';
import { useDispatch, useSelector } from 'react-redux';

// Prime-React Components
import { Menu } from 'primereact/menu';
import { Chart } from 'primereact/chart';
import { Button } from 'primereact/button';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-datalabels';

// Components
import { CustomeTooltip } from './ActionButtons';

// Utils
import { getBarOptions } from '../utils/chart';
import { isDarkMode, wordCapitalize } from '../utils/common';

// Images
import filterIc from '@assets/images/filter-ic.svg';
import filterIcDark from '@assets/images/filter-ic-dark.svg';
import { getScheduleInterviewCountByHR } from '../redux/actions/hr';
import { getDateWithDay } from '../utils/date';
import moment from 'moment';

const AvailableInterviewersBarChart = () => {
  const menu = useRef(null);
  const dispatch = useDispatch();
  const [hrData, setHrData] = useState([]);
  const [filter, setFilter] = useState('day');
  const scheduleInterviewCount = useSelector((state) => state.hrs.scheduleInterviewCount);

  function modifiedHrData(data) {
    const scheduleInterviews = data || [];
    let labels = [];
    let interviewsData = [];

    scheduleInterviews.forEach((hr) => {
      let hrName = [];
      hrName.push(hr?.FirstName ? wordCapitalize(hr?.FirstName) : '');
      hrName.push(hr?.LastName ? wordCapitalize(hr?.LastName) : '');
      labels.push(hrName);
      let candidates = new Set();
      if (hr?.RoundOfHR?.length) {
        hr?.RoundOfHR.forEach((round) => {
          candidates.add(round?.Interview?.CandidateID);
        });
      }
      interviewsData.push(candidates.size);
    });
    return { labels, interviewsData };
  }

  useEffect(() => {
    setHrData(modifiedHrData(scheduleInterviewCount));
  }, [scheduleInterviewCount]);

  const todaysInterviewerChartData = {
    labels: hrData?.labels || [],
    datasets: [
      {
        data: hrData?.interviewsData || [],
        borderWidth: 0,
        borderRadius: 50,
        barThickness: 20,
        backgroundColor: isDarkMode()
          ? [
              '#361C63',
              '#883318',
              '#096465',
              '#254555',
              '#035174',
              '#AA385F',
              '#156A0C',
              '#2A707A',
              '#6A9637'
            ]
          : [
              '#9575CD',
              '#FF8A65',
              '#64B5F6',
              '#90A4AE',
              '#4FC3F7',
              '#F06292',
              '#81C784',
              '#4DD0E1',
              '#AED581'
            ],
        datalabels: {
          color: '#FFF'
        }
      }
    ]
  };

  const technologyFilter = [
    {
      label: 'Today',
      value: 1,
      command: () => setFilter('day'),
      className: filter === 'day' ? 'highlighted-item' : ''
    },
    {
      label: 'Weekly',
      value: 1,
      command: () => setFilter('week'),
      className: filter === 'week' ? 'highlighted-item' : ''
    },
    {
      label: 'Monthly',
      value: 2,
      command: () => setFilter('month'),
      className: filter === 'month' ? 'highlighted-item' : ''
    }
  ];

  useEffect(() => {
    var startOfWeek = getDateWithDay(moment().startOf(filter).toDate());
    var endOfWeek = getDateWithDay(moment().endOf(filter).toDate());
    if (filter) {
      dispatch(getScheduleInterviewCountByHR(startOfWeek, endOfWeek));
    }
  }, [dispatch, filter]);

  return (
    <div className="white-box-item full-width">
      <div className="white-box">
        <div className="card-title-block">
          <EqualHeightElement name="title">
            <div className="card-title-wrapper">
              <span className="card-title">Number of Candidates schedule Interview by HRs</span>
              <i
                className="custom-target-icon pi pi-info-circle pl-1"
                data-tip="Number of Candidates count of schedule interview by HRs (Cancelled interview round not counted)"
                style={{ cursor: 'pointer' }}
              />
            </div>
          </EqualHeightElement>
          <div className="filter-block">
            <Menu
              model={technologyFilter}
              popup
              ref={menu}
              id="popup_menu"
              appendTo="self"
              className="popup_menu action-popup chartFilterMenu"
            />
            <CustomeTooltip />
            <Button
              data-tip="Filter By"
              type="button"
              onClick={(event) => menu.current.toggle(event)}
              aria-controls="popup_menu"
              aria-haspopup
              className="p-button-rounded p-button-text border-none primary-icon-btn filter-btn">
              <i>
                <img src={filterIc} className="default-img" alt="filter" />
                <img src={filterIcDark} className="dark-mode-img" alt="filter" />
              </i>
            </Button>
          </div>
        </div>
        <div className="chart-container">
          <div className="chart-figure-wrapper" style={{ width: '1000px' }}>
            <Chart
              type="bar"
              data={todaysInterviewerChartData}
              options={getBarOptions()}
              plugins={[ChartDataLabels]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvailableInterviewersBarChart;
